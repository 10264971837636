import React, { Fragment, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import stylebtn from '../../styles/btn.module.scss';
import CardProductoComponent from '../../components/CardProductoComponent';
import PaginadorComponent from '../../components/PaginadorComponent';
import TitleH1Component from '../../components/TitleH1Component';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import InputComponent from '../../components/InputComponent';
import SelectComponent from '../../components/SelectComponent';
import ParrafoComponent from '../../components/ParrafoComponent';
import { useParams, useLocation } from 'react-router-dom';
import { EditarBloque, EliminarBloque, getBloqueByID, PostProducto, PostPuesto } from '../../api/ApiVenta';
import LoaderComponent from '../../components/LoaderComponent';
import AlertComponent from '../../components/AlertComponent';
import { getTipos } from '../../api/Tipo';
import { getClases } from '../../api/Clase';
import MoneyInputComponent from '../../components/MoneyInputComponent';
const PuestosPage = () => {
	const { id } = useParams();
	const location = useLocation();
	const [show, setShow] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showEliminar, setShowEliminar] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [mensaje, setMensaje] = useState('');
	const [alert, setAlert] = useState(false);
	const [optionsclass, setOptionsClass] = useState([]);
	const [selectedOptionClass, setSelectedOptionClass] = useState(null);
	const [optionstype, setOptionstype] = useState([]);
	const [selectedOptionType, setSelectedOptionType] = useState(null);

	//const [data] = useState([...Array(7).keys()]); // Simulando datos, un array de 100 elementos
	const [data, setData] = useState([]);
	const [numeroB, setNumeroB] = useState('');
	const itemsPerPage = 12;
	const [numeropuesto, setNumeropuesto] = useState('')
	const [bloqueEdit, setBloqueEdit] = useState('');
	const [formData, setFormData] = useState({
		dimension: '',
		cantidad: '',
		precioCompra: '',
		precioVenta: '',
	});

	const handleInputChangePuesto = (event) => {
		setNumeropuesto(event.target.value);
	};

	const pageCount = Math.ceil(data.length / itemsPerPage);
	const currentPageDataBloque = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// metodos para el popup de editar
	const handleCloseEdit = () => setShowEdit(false);
	const handleShowEdit = () => {
		setShowEdit(true)

	};

	// metodos para el popup de eliminar
	const handleCloseEliminar = () => setShowEliminar(false);
	const handleShowEliminar = () => setShowEliminar(true);

	// Manejar el cambio en el input
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};



	useEffect(() => {
		getClases().then((response) => {

			const formattedOptions = response?.data?.map(item => ({
				value: item.id,
				label: item.name
			}));
			setOptionsClass(formattedOptions)

		})
			.catch(error => {
				console.log(error.response);
			});
	}, [])


	useEffect(() => {
		getTipos().then((response) => {

			const formattedOptions = response?.data?.map(item => ({
				value: item.id,
				label: item.name
			}));
			setOptionstype(formattedOptions)

		})
			.catch(error => {
				console.log(error.response);
			});
	}, [])


	//metodo para obtener los datos de la URL
	const getFiltersFromQuery = () => {
		const queryParams = new URLSearchParams(location.search);
		const filters = {
			idClase: queryParams.get('idClase') || null,
			idTipo: queryParams.get('idTipo') || null,
			dimension: queryParams.get('dimension') || null,
		};

		return filters;
	};

	useEffect(() => {
		const filters = getFiltersFromQuery();
		fetchBloque(filters);
	}, [location.search]);


	useEffect(() => {
		//fetchBloque();
	}, [id])

	const fetchBloque = async (filters = {}) => {
		setLoading(true);
		try {
			const bloque = await getBloqueByID(id, filters);
			setNumeroB(bloque.data.name);
			const puestoOrdenados = bloque.data.Puestos.sort((a, b) => a.numero - b.numero);

			// Para cada puesto, combinar los productos por tipo y sumar sus cantidades
			const puestosConTotal = puestoOrdenados.map((puesto) => {
				// Utilizamos un objeto auxiliar para combinar productos por tipo
				const productosPorTipo = {};

				puesto.Productos.forEach((producto) => {
					const tipoName = producto.Tipo.name;
					const claseName = producto.Clase.name;

					// Crear una clave única combinando tipo y clase
					const key = `${tipoName}-${claseName}`;

					// Si el tipo ya existe, sumamos la cantidad
					if (productosPorTipo[key]) {
						productosPorTipo[key].cantidad += producto.cantidad;
					} else {
						// Si no existe, agregamos el producto al objeto
						productosPorTipo[key] = { ...producto };
					}
				});



				// Convertimos el objeto de vuelta a un array de productos
				const productosSinDuplicados = Object.values(productosPorTipo);

				// Sumar las cantidades de todos los productos (ya sin duplicados)
				const totalCantidad = productosSinDuplicados.reduce((acc, producto) => acc + producto.cantidad, 0);

				// Devolver el puesto con los productos filtrados y la suma total de cantidades
				return {
					...puesto, // Mantiene las propiedades originales del puesto
					Productos: productosSinDuplicados, // Actualizamos los productos sin duplicados
					totalCantidad, // Agregamos el total de las cantidades
				};
			});

			// Actualizar el estado con los puestos que tienen la propiedad totalCantidad
			setData(puestosConTotal);
			setBloqueEdit(bloque.data.name);

		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	// agregamos el puesto 
	const aggPuesto = async () => {

		await PostPuesto(id, numeropuesto).then((response) => {

			setMensaje(response.msg);
			const nuevoPuesto = response.data;
			setData(prevData => {
				const nuevaLista = [...prevData, nuevoPuesto];
				return nuevaLista.sort((a, b) => a.numero - b.numero);
			});
			if (response.success) {
				PostProducto(
					response.data.id,
					selectedOptionClass,
					selectedOptionType,
					formData.dimension,
					formData.cantidad,
					formData.precioCompra,
					formData.precioVenta
				);
				fetchBloque();
			}

			setShow(false);
			setAlert(true);
		})
			.catch(error => {
				console.error(error.response.data);
				setMensaje(error.response.data.msg);
			});


		setTimeout(() => {
			setMensaje('')
			setAlert(false)
		}, [5000]);
	}

	const handleBloqueEditChange = (e) => {
		setBloqueEdit(e.target.value);
	}

	const editarbloque = () => {
		if (bloqueEdit === '') {
			setMensaje('El campo es obligatorio');
		} else {
			EditarBloque(id, bloqueEdit).then((response) => {

				setMensaje(response.msg);
				setNumeroB(bloqueEdit);

				console.log(response)
				setShowEdit(false);
				setAlert(true);

			})
				.catch(error => {
					console.error(error.response.data);
					setMensaje(error.response.data.msg);
				});
		}
		setTimeout(() => {
			setAlert(false)
			setMensaje('')

		}, [5000]);
	}

	const eliminarbloque = () => {

		EliminarBloque(id).then((response) => {
			setMensaje(response.msg);
			window.location.href = '/home'

		})
			.catch(error => {
				console.error(error.response.data);
			});

		setTimeout(() => {
			setMensaje('')
		}, [5000]);
	}

	return (
		<Fragment>
			<div className='d-flex justify-content-center'>
				<AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
			</div>
			<HeaderComponent styleGroup="">
				<TitleH1Component styleGroup="box-text color-dark fw700 roboto mb-4">B{numeroB}</TitleH1Component>
				<button type="button" className={`${stylebtn.btn} ${stylebtn.primary} me-2 mt-2`} onClick={handleShow}>
					Crear Puesto
				</button>
				<button type="button" className={`${stylebtn.btn} ${stylebtn.secundary} me-2 mt-2`} onClick={handleShowEdit}>
					Editar Bloque
				</button>
				<button type="button" className={`${stylebtn.btn} ${stylebtn.danger} mt-2`} onClick={handleShowEliminar}>
					Eliminar Bloque
				</button>
			</HeaderComponent>
			{loading ? (
				<LoaderComponent styleGroup="mt-5" />
			) : data.length !== 0 ? (
				<div className="conatiner-global pt-3 pb-5">
					<TitleH2Component styleGroup="box-text color-dart text-center fw700 WorkSans mb-0 pb-4">
						PUESTOS
					</TitleH2Component>
					<div className="container">
						<div className="row mt-0 gy-4">
							{currentPageDataBloque.map((item, index) => {
								return (
									<div className="col-lg-4 col-xl-3 col-6  " key={index}>
										<CardProductoComponent cardpuesto item={item} />
									</div>
								);
							})}
						</div>

						{pageCount > 1 && (
							<PaginadorComponent
								setCurrentPage={setCurrentPage}
								pages={pageCount}
								styleGroup="d-flex justify-content-center pt-4"
							/>
						)}
					</div>
				</div>
			) : (
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mt-5 text-center WorkSans">
					SIN PUESTOS
				</TitleH2Component>
			)}

			{/** modal Registro de un puesto**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo="NUEVO PUESTO"
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={show}
					handleClose={handleClose}
					handleShow={handleShow}
				>
					<div className="container">
						<form action="" >
							<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>

							<div className="row">
								<div className="col-12">
									<InputComponent
										label="Numero del puesto"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										type="number"
										htmlFor="puesto"
										value={numeropuesto}
										name="puesto"
										onChange={handleInputChangePuesto}
									/>
								</div>
								<div className="col-6">
									<SelectComponent
										styleGroup="mb-2"
										label="Clase de madera"
										styleLabel="fw600 WorkSans color-madera"
										options={optionsclass}
										name="clase"
										value={selectedOptionClass}
										capOpcions={setSelectedOptionClass}
									/>
								</div>
								<div className="col-6">
									<SelectComponent
										styleGroup="mb-2"
										label="Tipo madera"
										styleLabel="fw600 WorkSans color-madera"
										name="tipo"
										options={optionstype}
										value={selectedOptionType}
										capOpcions={setSelectedOptionType}
									/>
								</div>
								<div className="col-6">
									<InputComponent
										label="Dimension"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="cantidad"
										type="text"
										value={formData.dimension}
										name='dimension'
										onChange={handleInputChange}
									/>
								</div>

								<div className="col-6">
									<InputComponent
										label="Cantidad"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="cantidad"
										type="number"
										value={formData.cantidad}
										name='cantidad'
										onChange={handleInputChange}
									/>
								</div>
								<div className="col-6">
									<MoneyInputComponent
										label="Precio de compra"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="precio"
										type="number"
										value={formData.precioCompra}
										name='precioCompra'
										onChange={handleInputChange}
									/>
								</div>
								<div className="col-6">
									<ParrafoComponent styleGroup=" color-gris fw400 WorkSans">precio de compra total: {Number(formData.precioCompra * formData.cantidad).toLocaleString('es-ES')}</ParrafoComponent>

								</div>
								<div className="col-6">
									<MoneyInputComponent
										label="Precio de venta por unidad"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="precio"
										type="number"
										value={formData.precioVenta}
										name='precioVenta'
										onChange={handleInputChange}
									/>
								</div>
								<div className="col-6">
									<ParrafoComponent styleGroup=" color-gris fw400 WorkSans">precio total de venta es: {Number(formData.precioVenta * formData.cantidad).toLocaleString('es-ES')}</ParrafoComponent>

								</div>
							</div>
							<div className="d-flex justify-content-end">
								<button type="button" onClick={handleClose} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
									Cancelar
								</button>
								<button type="button" onClick={aggPuesto} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
									Agregar
								</button>
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>

			{/** modal editar un bloque**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo="EDITAR BLOQUE"
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEdit}
					handleClose={handleCloseEdit}
					handleShow={handleShowEdit}
				>
					<form action="" >
						<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>

						<InputComponent
							placeholder="Ingrese el numero"
							label="Nombre del bloque:"
							styleLabel="fw600 WorkSans"
							styleGroup=" mb-4"
							htmlFor="bloque"
							type="number"
							value={bloqueEdit}
							onChange={handleBloqueEditChange}
						// value={inputValue}
						//onChange={handleInputChange}
						/>
						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEdit} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" onClick={editarbloque} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								OK
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>

			{/** modal eliminar un bloque**/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
					handleShow={handleShowEliminar}
				>
					<form action="">
						<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>

						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">
							Seguro que quiere eliminar el Bloque B{numeroB} ?
						</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" onClick={eliminarbloque} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};

export default PuestosPage;
