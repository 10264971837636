import React, { Fragment, useState, useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import InputComponent from '../../components/InputComponent';
import SelectComponent from '../../components/SelectComponent';
import stylebtn from '../../styles/btn.module.scss';
import stylegeneral from '../../styles/general.module.scss';
import styleform from '../../styles/form.module.scss';
import { BuscarCliente, PostCliente } from '../../api/ApiVenta';
import ParrafoComponent from '../../components/ParrafoComponent';
import { getClases } from '../../api/Clase';
import { FiltrarProducto } from '../../api/Filters';
import { getTipos } from '../../api/Tipo';
import { PostPersona } from '../../api/Empleado';
import { EliminarFactura, Postfactura, PostfacturaDetalle } from '../../api/Factura';
import LoaderComponent from '../../components/LoaderComponent';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import { Icon } from '@iconify/react/dist/iconify.js';
import MoneyInputComponent from '../../components/MoneyInputComponent';
import styleBtn from '../../styles/btn.module.scss';
const CotizacionPage = () => {
	const [mensaje, setMensaje] = useState('');
	const [optionsclass, setOptionsClass] = useState([]);
	const [selectedOptionClass, setSelectedOptionClass] = useState(null);
	const [optionstype, setOptionstype] = useState([]);
	const [selectedOptionType, setSelectedOptionType] = useState(null);
	const [dimensionOpcion, setDimensionOpcion] = useState([]);
	const [selectedOptionDimension, setSelectedOptionDimension] = useState(null);
	const [cantidad, setCantidad] = useState('');
	const [currentProducto, setCurrentProducto] = useState([]);
	const [cotizacion, setCotizacion] = useState([]);
	const [subTotal, setSubTotal] = useState(0);
	const [descuento, setDescuento] = useState(0);
	const [totalPago, setTotalPago] = useState(0);
	const [control, setcontrol] = useState(false);
	const [fechaPago, setFechaPago] = useState(null);
	const [loading, setLoading] = useState(false);
	const [currentCliente, setCurrentCliente] = useState([]);
	const [idCliente, setIdCliente] = useState('');
	const [currentPersona, setCurrentPersona] = useState([]);
	const [showCotizacion, setShowCotizacion] = useState(false);
	const [currentDate, setCurrentDate] = useState('');
	const [idfactura, serIdfactura] = useState(null);
	const [showEliminar, setShowEliminar] = useState(false);
	const [currentCarrito, setCurrentCarrito] = useState([]);
	const [formDataPersona, setFormDataPersona] = useState({
		cedula: '',
		nombre: '',
		apellido: '',
		direccion: '',
		celular: '',
	});

	// Metodos para el popup del cliente
	const handleCloseCotizacion = () => {
		setShowCotizacion(false);
	};

	const handleShowCotizacion = () => {
		setShowCotizacion(true);
	};

	const [checkboxes, setCheckboxes] = useState({
		isCheckedDescuento: false,
		isCheckedpago: false,
	});

	// este es el metodo para sacar el valor del check
	const handleCheckboxChange = (event) => {
		const { id, checked } = event.target;
		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[id]: checked,
		}));
	};

	// Metodos para el popup de eliminar
	const handleCloseEliminar = () => setShowEliminar(false);
	const handleShowEliminar = (item) => {
		setShowEliminar(true);
		setCurrentCarrito(item);
	};

	// metodo para eliminar del carrito de compra
	const eliminarItem = () => {
		const nuevoCarrito = cotizacion.filter((item) => item.id !== currentCarrito?.id);
		setCotizacion(nuevoCarrito);
		localStorage.setItem('cotizacion', JSON.stringify(nuevoCarrito));
		handleCloseEliminar();
	};

	// Manejar el cambio en el input para las fechas de pagos
	const handleInputChangefechapago = (event) => {
		setFechaPago(event.target.value);
	};

	// Manejar el cambio en el input para los descuentos
	const handleInputPago = (event) => {
		setTotalPago(event.target.value);
	};
	// Manejar el cambio en el input para los descuentos
	const handleInputChangedescuento = (event) => {
		setDescuento(event.target.value);
	};

	// Manejar el cambio en el input
	const handleInputChange = (event) => {
		setCantidad(event.target.value);
	};

	// Manejar el cambio en el input
	const handleInputChangePersona = (event) => {
		const { name, value } = event.target;
		setFormDataPersona({
			...formDataPersona,
			[name]: value,
		});
	};

	useEffect(() => {
		getClases()
			.then((response) => {
				const formattedOptions = response?.data?.map((item) => ({
					value: item.id,
					label: item.name,
				}));
				setOptionsClass(formattedOptions);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	useEffect(() => {
		getTipos()
			.then((response) => {
				const formattedOptions = response?.data?.map((item) => ({
					value: item.id,
					label: item.name,
				}));
				setOptionstype(formattedOptions);
			})
			.catch((error) => {
				console.log(error.response);
			});
	}, []);

	// metodo para consultar la cc
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			consultarCedula();
		}
	};
	const consultarCedula = async () => {
		try {
			const res = await BuscarCliente(formDataPersona.cedula);
			setcontrol(res.success);
			if (res.success) {
				setFormDataPersona({
					cedula: res?.data?.cedula,
					nombre: res?.data?.nombre,
					apellido: res?.data?.apellido,
					direccion: res?.data?.direccion,
					celular: res?.data?.celular,
				});
				setCurrentPersona(res?.data);
				setIdCliente(res?.data?.Clientes[0]?.id);
				setCurrentCliente(res?.data?.Clientes);
				localStorage.setItem('cliente', JSON.stringify(res?.data));
			} else {
				/*setIdCliente('')*/

				setMensaje(res.msg);
				setFormDataPersona({
					cedula: formDataPersona.cedula,
					nombre: '',
					apellido: '',
					direccion: '',
					celular: '',
				});
			}

			/*setcontrol(res.success)*/
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};

	// metodo para buscar un producto

	//metodo de filtro
	useEffect(() => {
		filtroproducto();
	}, [selectedOptionClass, selectedOptionType, selectedOptionDimension]);

	const filtroproducto = () => {
		FiltrarProducto({
			idClase: selectedOptionClass ?? '',
			idTipo: selectedOptionType ?? '',
			dimension: selectedOptionDimension ?? '',
		})
			.then((response) => {
				const formattedOptionsDimension = response?.data?.map((item) => ({
					value: item.dimension,
					label: item.dimension,
				}));
				setDimensionOpcion(formattedOptionsDimension);
				setCurrentProducto(response?.data[0]);
			})
			.catch((error) => console.error(error));
	};

	// metodo para agregar a la cotizacion
	const aggCotizacion = () => {
		if (selectedOptionClass === '' || selectedOptionType === '' || selectedOptionDimension === '' || cantidad === '') {
			setMensaje('Los campos son obligatorios **');
		} else {
			const carritoActual = JSON.parse(localStorage.getItem('cotizacion')) || [];

			// Verificar si el producto ya existe en la cotizacion
			const productoExistente = carritoActual.find((producto) => producto.id === currentProducto?.id);
			// Si no existe, crear el nuevo objeto de compra
			if (productoExistente) {
				const nuevaCantidad = Number(productoExistente.pendientePorEntregar) + Number(cantidad);
				productoExistente.pendientePorEntregar = nuevaCantidad;
				productoExistente.valorTotalPorProducto = nuevaCantidad * currentProducto?.precioVenta;
			} else {
				const nuevoProducto = {
					id: currentProducto?.id,
					clase: currentProducto?.Clase?.name,
					tipo: currentProducto?.Tipo?.name,
					dimension: currentProducto?.dimension,
					cantidad: 0,
					valorTotalPorProducto: cantidad * currentProducto?.precioVenta,
					valorPorUnidad: currentProducto?.precioVenta,
					pendientePorEntregar: cantidad,
				};

				// Agregar el nuevo producto al carrito
				carritoActual.push(nuevoProducto);
			}

			localStorage.setItem('cotizacion', JSON.stringify(carritoActual));
			setCotizacion(carritoActual);
		}

		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};

	useEffect(() => {
		const carritoActual = JSON.parse(localStorage.getItem('cotizacion')) || [];
		setCotizacion(carritoActual);
	}, []);

	// useEffect para recalcular el total cuando cambie el carrito
	useEffect(() => {
		const calcularTotal = (cotizacion) => {
			return cotizacion.reduce((total, item) => total + item.valorTotalPorProducto, 0);
		};

		// Actualiza el total cada vez que 'carrito' cambie
		const total = calcularTotal(cotizacion);
		setSubTotal(total);
	}, [cotizacion]);

	// metodo para crear una cotizacion
	const craeteCotizacion = async (e) => {
		e.preventDefault();

		try {
			let total = 0;
			let descuentototal = 0;
			let financiado = 0;
			let estado = 2;
			let isventa = false;

			// para validar si se aplicara el descuento
			if (checkboxes.isCheckedDescuento) {
				total = subTotal - descuento;
				descuentototal = descuento;
			} else {
				total = subTotal;
				descuentototal = 0;
			}

			financiado = total - totalPago;

			if (formDataPersona.cedula === '' || formDataPersona.nombre === '' || formDataPersona.apellido === '') {
				setMensaje('Antes de realizar la cotizacion de asignar un cliente');
			} else if (!cotizacion || cotizacion.length === 0) {
				setMensaje('Antes de realizar la cotizacion debe asignar los productos');
			} else if (fechaPago === null && financiado > 0 && checkboxes.isCheckedpago) {
				setMensaje('Antes de realizar la cotizacion debe ingresar la fecha limite de pago');
			} else {
				if (checkboxes.isCheckedpago) {
					// verificamos que la factura este pendiente o financiada
					if (financiado === 0) {
						estado = 2;
					} else {
						estado = 1;
					}

					// si la perosna no exite en la base de datos
					if (!control || control == false) {
						const respersona = await PostPersona(formDataPersona);
						if (respersona.success) {
							setLoading(true);
							handleShowCotizacion();
							const rescliente = await PostCliente(respersona.data.idP);

							if (rescliente.success) {
								const resf = await Postfactura(
									rescliente.data.id,
									estado,
									subTotal,
									fechaPago,
									descuentototal,
									financiado,
									total
								);

								if (resf?.success) {
									const resFD = await PostfacturaDetalle(isventa, resf.data.id, cotizacion);

									if (resFD?.success) {
										//setCompraExitosa(true);
										// Ahora limpiamos el carrito.
										localStorage.removeItem('cotizacion');
										setLoading(false);
										serIdfactura(resf.data.id);
									} else {
										await EliminarFactura(resf.data.id);
										setMensaje('Fallo la compra, revise y vuelva a intentarlo');
										setLoading(false);
									}
								}
							}
						}
						setMensaje(respersona.msg);
					} else {
						setLoading(true);
						handleShowCotizacion();
						// si la persona existe en la base de datos pero no es cliente
						if (idCliente === undefined || currentCliente?.length === 0) {
							const rescliente = await PostCliente(currentPersona?.idP);

							if (rescliente.success) {
								const resf = await Postfactura(
									rescliente.data.id,
									estado,
									subTotal,
									fechaPago,
									descuentototal,
									financiado,
									total
								);
								if (resf?.success) {
									const resFD = await PostfacturaDetalle(isventa, resf.data.id, cotizacion);
									if (resFD?.success) {
										//setCompraExitosa(true);
										// Ahora limpiamos el carrito.
										localStorage.removeItem('cotizacion');
										setLoading(false);
										serIdfactura(resf.data.id);
									} else {
										await EliminarFactura(resf.data.id);
										setMensaje('Fallo la compra, revise y vuelva a intentarlo');
										setLoading(false);
									}
								}
							}
						} else {
							// Si la perosona es cliente
							if (idCliente !== '') {
								const resf = await Postfactura(
									idCliente,
									estado,
									subTotal,
									fechaPago,
									descuentototal,
									financiado,
									total
								);

								if (resf?.success) {
									const resFD = await PostfacturaDetalle(isventa, resf.data.id, cotizacion);

									if (resFD?.success) {
										//setCompraExitosa(true);
										// Ahora limpiamos el carrito.
										localStorage.removeItem('cotizacion');
										setLoading(false);
										serIdfactura(resf.data.id);
									} else {
										await EliminarFactura(resf.data.id);
										setMensaje('Fallo la compra, revise y vuelva a intentarlo');
										setLoading(false);
									}
								}
							}
						}
					}
				} else {
					localStorage.setItem(
						'factura',
						JSON.stringify({
							subtotal: subTotal,
							estado: 3,
							descuento: descuentototal,
							fechaPago,
							fechafactura: currentDate,
							financiado: 0,
							total,
							numeroFactura: Math.floor(1000 + Math.random() * 9000),
						})
					);
					// verifiquemos que la persona no exita en la base de datos
					if (!control || control == false) {
						const respersona = await PostPersona(formDataPersona);
						setMensaje(respersona.msg);
						localStorage.setItem('cliente', JSON.stringify(respersona));
						if (respersona.success) {
							setLoading(true);
							handleShowCotizacion();
							const rescliente = await PostCliente(respersona.data.idP);
							if (rescliente.success) {
								setLoading(false);
							}
						}
					} else {
						setLoading(true);
						handleShowCotizacion();
						// si la persona existe en la base de datos pero no es cliente
						if (idCliente === undefined || currentCliente?.length === 0) {
							const rescliente = await PostCliente(currentPersona?.idP);
							if (rescliente.success) {
								setLoading(false);
							}
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 3000);
						}
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};

	useEffect(() => {
		// Capturar la fecha actual
		const today = new Date();
		setCurrentDate(today.toLocaleDateString()); // Formato de fecha actual
	}, []);

	const verificarfactura = () => {
		if (idfactura != null) {
			window.location.href = `/generarfactura/${idfactura}`;
		} else {
			window.location.href = `/generarfactura`;
		}
	};

	const limpiar = () => {
		localStorage.removeItem('cotizacion');
		setCotizacion([]);
	};

	return (
		<Fragment>
			<HeaderComponent>
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">COTIZACION</TitleH2Component>
			</HeaderComponent>
			<div className="conatiner-global pt-3 mb-5">
				<section>
					<form action="">
						<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans mb-2">{mensaje}</ParrafoComponent>

						<div className={`${stylegeneral.containerFormCotiza} mb-5`}>
							<div className={`container`}>
								<div className="row gy-3">
									<div className="col-12 col-md-6 mb-1">
										<input
											type="text"
											htmlFor="cudula"
											className="form-control fw600 WorkSans mb-4"
											placeholder="Ingrese la cc"
											name="cedula"
											value={formDataPersona.cedula}
											onChange={handleInputChangePersona}
											onKeyDown={handleKeyDown}
										/>
									</div>

									<div className="col-12 col-md-6 col-xl-12  mb-1">
										<InputComponent
											placeholder="Nombre:"
											styleLabel="fw600 WorkSans"
											htmlFor="bloque"
											type="text"
											value={formDataPersona.nombre}
											name="nombre"
											onChange={handleInputChangePersona}
										/>
									</div>

									<div className="col-12 col-md-6  mb-1">
										<InputComponent
											placeholder="Primer Apellido:"
											styleLabel="fw600 WorkSans"
											styleGroup=""
											htmlFor="bloque"
											type="text"
											value={formDataPersona.apellido}
											name="apellido"
											onChange={handleInputChangePersona}
										/>
									</div>

									<div className="col-12 col-md-6 ">
										<InputComponent
											placeholder="Direccion:"
											styleLabel="fw600 WorkSans"
											styleGroup=""
											htmlFor="bloque"
											type="text"
											value={formDataPersona.direccion}
											name="direccion"
											onChange={handleInputChangePersona}
										/>
									</div>
									<div className="col-12 col-md-6 ">
										<InputComponent
											placeholder="Celular:"
											styleLabel="fw600 WorkSans"
											styleGroup=""
											htmlFor="bloque"
											type="text"
											value={formDataPersona.celular}
											name="celular"
											onChange={handleInputChangePersona}
										/>
									</div>
								</div>
							</div>

							<div className={`${stylegeneral.hija_2} `}>
								<label className="form-label fw-bold ">{currentDate}</label>
								<div className="d-flex ">
									<input
										className=" me-2"
										type="checkbox"
										value=""
										id="isCheckedDescuento"
										checked={checkboxes.isCheckedDescuento}
										onChange={handleCheckboxChange}
									/>

									<label className="color-dark  fw700 me-2" htmlFor="recorddat">
										Aplica descuento
									</label>
								</div>

								{checkboxes.isCheckedDescuento && (
									<div className="d-flex flex-column align-items-end">
										<MoneyInputComponent
											placeholder="Cant."
											styleLabel="fw600 WorkSans"
											styleGroup="mt-2  mb-2"
											htmlFor="descuento"
											type="number"
											name="descuento"
											value={descuento}
											onChange={handleInputChangedescuento}
										/>

										<button type="button" className={`d-none ${stylebtn.btn} ${stylebtn.primary} `}>
											OK
										</button>
									</div>
								)}
							</div>
						</div>
					</form>
				</section>

				<section>
					<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans mb-2">{mensaje}</ParrafoComponent>

					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-md-6 col-xl-3 mt-4">
								<SelectComponent
									styleLabel="color-madera fw600"
									/*options={options}*/
									customPlaceholder="Clase"
									options={optionsclass}
									name="clase"
									value={selectedOptionClass}
									capOpcions={setSelectedOptionClass}
									isClearable
								/>
							</div>
							<div className="col-12 col-md-6 col-xl-3 mt-4">
								<SelectComponent
									styleLabel="color-madera fw600"
									/*options={options}*/
									customPlaceholder="tipo"
									options={optionstype}
									name="tipo"
									value={selectedOptionType}
									capOpcions={setSelectedOptionType}
									isClearable
								/>
							</div>
							<div className="col-12 col-md-6 col-xl-2 mt-4">
								<SelectComponent
									styleLabel="color-madera fw600"
									/*options={options}*/
									customPlaceholder="dimension"
									options={dimensionOpcion}
									name="dimension"
									value={selectedOptionDimension}
									capOpcions={setSelectedOptionDimension}
									isClearable
								/>
							</div>
							<div className="col-12 col-md-6 col-xl-2 mt-4">
								<InputComponent
									placeholder="Cant."
									styleLabel="fw600 WorkSans"
									styleGroup=""
									htmlFor="bloque"
									type="number"
									name="cantidad"
									value={cantidad}
									onChange={handleInputChange}
								/>
							</div>
							<div className="col-12 col-md-6 col-xl-2 mt-4">
								<button type="button" className={`${stylebtn.btn} ${stylebtn.primary} `} onClick={aggCotizacion}>
									AGREGAR
								</button>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className="table-responsive mt-4">
						<table className="table table-bordered tableGeneral">
							<thead>
								<tr>
									<th scope="col" className="color-madera">
										Cantidad
									</th>
									<th scope="col" className="color-madera">
										Descripción
									</th>
									<th scope="col" className="color-madera">
										Valor por Unidad
									</th>
									<th scope="col" className="color-madera">
										Valor Total
									</th>
								</tr>
							</thead>
							<tbody>
								{cotizacion.map((item, index) => {
									return (
										<tr key={index}>
											<th>{item?.pendientePorEntregar}</th>
											<td>
												{item?.tipo} {item?.clase} {item?.dimension}
											</td>
											<td>{Number(item?.valorPorUnidad).toLocaleString('es-ES')}</td>
											<td>${Number(item?.valorTotalPorProducto).toLocaleString('es-ES')}</td>
											<td>
												<button
													className={`${styleBtn.btn_desnudo} ${styleBtn.icon} ms-3 p-0 d-inline-flex color-danger`}
													onClick={() => handleShowEliminar(item)}
												>
													<Icon icon="majesticons:delete-bin" />
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td colSpan="3"></td>
									<td className="text-right">SUBTOTAL</td>
									<td>$ {Number(subTotal).toLocaleString('es-ES')}</td>
								</tr>
								<tr>
									<td colSpan="3"></td>
									<td className="text-right">DESCUENTO</td>
									<td>$ {Number(checkboxes.isCheckedDescuento ? descuento : 0).toLocaleString('es-ES')}</td>
								</tr>
								<tr>
									<td colSpan="3"></td>
									<td className="text-right">FINANCIADO</td>
									<td>
										${' '}
										{Number(subTotal - (checkboxes.isCheckedDescuento ? descuento : 0) - totalPago).toLocaleString(
											'es-ES'
										)}
									</td>
								</tr>
								<tr className="d-none">
									<td colSpan="3"></td>
									<td className="text-right">PENDIENTE POR ENTREGA</td>
									<td>80</td>
								</tr>
								<tr>
									<td colSpan="3"></td>
									<td className="text-right">TOTAL</td>
									<td style={{ backgroundColor: 'lightgreen', fontWeight: 'bold' }}>
										$ {Number(checkboxes.isCheckedDescuento ? subTotal - descuento : subTotal).toLocaleString('es-ES')}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>

					<form action="">
						<button type="button" className={` ${stylebtn.btn} ${stylebtn.primary} `} onClick={limpiar}>
							Limpiar
						</button>
					</form>
				</section>

				<form action="" onSubmit={craeteCotizacion}>
					<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans mb-2">{mensaje}</ParrafoComponent>

					<div className="d-md-flex justify-content-between align-items-end mt-5 ">
						<div className="">
							<div className="mb-3">
								<input
									className=" me-2"
									type="checkbox"
									value=""
									id="isCheckedpago"
									checked={checkboxes.isCheckedpago}
									onChange={handleCheckboxChange}
								/>
								<label className="color-dark  fw700 me-2" htmlFor="recorddat">
									Cancelar
								</label>
							</div>

							{checkboxes.isCheckedpago && (
								<div className="d-flex flex-column">
									<MoneyInputComponent
										placeholder="Cant."
										styleLabel="fw600 WorkSans"
										styleGroup="mt-3 mt-sm-0 me-3 mb-3"
										htmlFor="pago"
										type="number"
										name="totalPago"
										value={totalPago}
										onChange={handleInputPago}
									/>
									<InputComponent
										label="Solo si la factura es financiada agrege la fecha de pago"
										placeholder="Cantidad a financiar"
										styleLabel="fw600 WorkSans"
										styleGroup=" mb-2"
										htmlFor="bloque"
										type="date"
										name="fechapago"
										value={fechaPago}
										onChange={handleInputChangefechapago}
									/>
								</div>
							)}
						</div>

						<button type="submit" className={`btn mt-4 mt-md-0 ${stylebtn.btn} ${stylebtn.primary} `}>
							OK
						</button>
					</div>
				</form>
			</div>

			{/** modal ingresar informacion del cliente **/}
			<section>
				<ModalGeneralComponent
					ModalHeader={false}
					titulo="Información del cliente"
					styleGroup={`d-flex align-items-center modal-general `}
					styleBody={`${'bg-gris-modal '}`}
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showCotizacion}
					handleClose={handleCloseCotizacion}
				>
					{loading ? (
						<LoaderComponent styleGroup="mt-5" />
					) : (
						<div>
							<div className="d-flex justify-content-end mb-0 align-items-center mb-3">
								<button
									type="button"
									onClick={verificarfactura}
									className={`btn ${stylebtn.btn} ${stylebtn.primary} me-3`}
								>
									Generar factura
								</button>
								<button
									type="button"
									className={`btn ${stylebtn.btn_desnudo} ${stylebtn.icon} p-0 d-inline-flex`}
									onClick={handleCloseCotizacion}
								>
									<Icon icon="mdi:close-thick" />
								</button>
							</div>
							<TitleH2Component styleGroup="box-text color-soccess fw700 roboto mb-0 text-center WorkSans">
								¡¡Cotizacion realizada con éxito!!
							</TitleH2Component>
						</div>
					)}
				</ModalGeneralComponent>
			</section>

			{/** modal eliminar un producto del carrito **/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere eliminar este producto ${currentCarrito?.tipo} ${currentCarrito?.clase} ?`}</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" onClick={eliminarItem} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};
export default CotizacionPage;
