import React, { Fragment, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import stylebtn from '../../styles/btn.module.scss';
import TitleH4Component from '../../components/TitleH4Component';
import InputComponent from '../../components/InputComponent';
import SelectComponent from '../../components/SelectComponent';
import { Icon } from '@iconify/react/dist/iconify.js';
import PaginadorComponent from '../../components/PaginadorComponent';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import stylegeneral from '../../styles/general.module.scss';
import ModalAbono from '../../components/ModalAbono';
import { useParams } from 'react-router-dom';
import {
	actualizarAbono,
	actualizarNomina,
	EliminarAbono,
	eliminarAbonos,
	EliminarRegistroNomina,
	obtenerNomina,
	PostAbonoNomina,
	PostpagoNomina,
	resetAdelantosNomina,
	verAbonoEmpleado,
} from '../../api/Empleado';
//import { deleteNomina } from '../../api/Nomina';
import LoaderComponent from '../../components/LoaderComponent';
import AlertComponent from '../../components/AlertComponent';
import MoneyInputComponent from '../../components/MoneyInputComponent';
const DetalleEmpleadoPage = () => {
	//para traer la data
	const { id } = useParams();
	const [currentPage, setCurrentPage] = useState(0);
	//const [data] = useState([...Array(6).keys()]); // Simulando datos, un array de 100 elementos
	const [loading, setLoading] = useState(false);
	const [currentEmpleado, setCurrentEmpleado] = useState([]);
	const [currentNomina, setCurrentNomina] = useState([]);
	const [data, setData] = useState([]);
	const [isAdelanto, setIsAdelanto] = useState(false);
	const [dataAbono, setDataAbono] = useState([]);
	const [estadonomina, setEstadonomina] = useState();
	const [mensaje, setMensaje] = useState('');
	const [alert, setAlert] = useState(false);


	const [showEliminar, setShowEliminar] = useState(false);

	const [showHora, setShowHora] = useState(false);
	const [showAdelanto, setShowAdelanto] = useState(false);
	const [showAbono, setShowAbono] = useState(false);
	const [showRestroA, setRegistroA] = useState(false);
	// Suponiendo que tienes un est ado para las nóminas
	const [nóminas, setNominas] = useState([]);
	const [showPagos, setShowPagos] = useState(false);
	const [showPago, setShowPago] = useState(false);
	const [idNomina, setIdNomina] = useState('');
	const [abono, setAbono] = useState(0);
	const [totalAbonos, setTotalAbono] = useState(0);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [showcancelarCuenta, setShowcancelarCuenta] = useState(false);
	const [formDataNomina, setFormDataNomina] = useState({
		horaEntrada: '',
		horaSalida: '',
		inicioReceso: '',
		finReceso: '',
		adelanto: 0,
		estadoPago: false,
	});

	let horamax = '16:30';
	let minamax = 510;
	let precioHoraExtra = 7000;

	const filteredData = data.filter((item) => {
		if (!estadonomina) return true;
		return item?.estadoPago === (estadonomina === 'pago' ? true : false);
	});

	//paginador
	const itemsPerPage = 7;
	const pageCount = Math.ceil(data.length / itemsPerPage);
	const currentPageDatadetalle = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);




	// Manejar el cambio en el input
	const handleInputChangeNomina = (event) => {
		if (isAdelanto) {
			const { name, value } = event.target;
			setFormDataNomina({
				...formDataNomina,
				[name]: value,
			});
		} else {
			setAbono(event.target.value);
		}
	};

	const handleCloseEliminar = () => setShowEliminar(false);
	//metodo para PAGAR
	const handleShowPagar = (item) => {
		let pago;
		if (item?.estadoPago == false || item?.estadoPago == 0) {
			pago = true;
		} else {
			pago = false;
		}
		setShowPago(true);
		setCurrentNomina(item);
		setFormDataNomina({
			horaEntrada: item.horaEntrada,
			inicioReceso: item.inicioReceso,
			finReceso: item.finReceso,
			horaSalida: item.horaSalida,
			adelanto: item.adelanto,
			estadoPago: pago,
		});
	};

	// metodos para el popup de eliminar
	const handleShowEliminar = (obj) => {
		setShowEliminar(true);
		setCurrentNomina(obj);
	};

	const eliminarRegistroNomina = async () => {
		console.log('Revisando los datos de currentnomina', currentNomina?.id);
		try {
			const res = await EliminarRegistroNomina(currentNomina?.id);

			if (res.success) {
				setAlert(true);
				setMensaje(res.message);
				fetchNomina();
				handleCloseEliminar();
			}
			console.log(res);
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setAlert(false);
		}, 3000);
	};

	const handleShowcancelarcuenta = () => {
		setShowcancelarCuenta(true)
	}

	const handleShowcancelarcuentaclose = () => {
		setShowcancelarCuenta(false)
	}

	const handleClosePagar = () => setShowPago(false);

	//metodo para ver registro de abono
	const handleShowRegistroA = () => {
		//fetchAbonoNomina();
		setRegistroA(true);
	};
	const handleCloseRegistroA = () => setRegistroA(false);

	//metodo para registrar el abono
	const handleShowAbono = () => {
		setIsAdelanto(false);
		setShowAbono(true);
	};
	const handleCloseAbono = () => setShowAbono(false);

	//metodo para registrar el adelanto
	const handleShowAdelanto = (item) => {
		setIsAdelanto(true);
		// Verifica que 'item' tenga los valores necesarios
		setCurrentNomina(item);
		setFormDataNomina({
			horaEntrada: item.horaEntrada,
			inicioReceso: item.inicioReceso,
			finReceso: item.finReceso,
			horaSalida: item.horaSalida,
			adelanto: item.adelanto,
			estadoPago: item.estadoPago,
		});
		setShowAdelanto(true);
	};

	const handleCloseAdelanto = () => setShowAdelanto(false);

	//metodo para registrar horas laborales
	const handleShowHora = (item) => {
		setIsAdelanto(true);
		setShowHora(true);
		setCurrentNomina(item);
		setFormDataNomina({
			horaEntrada: item?.horaEntrada,
			inicioReceso: item?.inicioReceso,
			finReceso: item?.finReceso,
			horaSalida: item?.horaSalida,
			adelanto: item?.adelanto,
			estadoPago: item?.estadoPago,
		});
	};
	const handleCloseHora = () => setShowHora(false);

	const options = [
		{ value: 'pago', label: 'PAGO' },
		{ value: 'nopago', label: 'NO PAGO' },
	];

	const fetchNomina = async (filters) => {
		setLoading(true);
		try {
			const empleado = await obtenerNomina(id, filters);
			setCurrentEmpleado(empleado?.data);
			setIdNomina(empleado?.data?.idEmpl);

			const formattedData = empleado.data.Nominas.map((item) => ({
				...item,
				formattedDate: new Date(item.createdAt).toLocaleDateString(), // Formatea la fecha sin usar hook
			}));
			setData(formattedData);

			const formattedDataAbono = empleado?.data?.AbonoEmpleados.map((item) => ({
				...item,
				formattedDate: new Date(item.createdAt).toLocaleDateString(), // Formatea la fecha sin usar hook
				formattedPrice: Number(item.monto).toLocaleString('es-ES'), // Formatea el precio con punto de miles
			}));
			setDataAbono(formattedDataAbono);
			// Sumar los montos
			const totalMontos = empleado?.data?.AbonoEmpleados.reduce((total, item) => {
				return total + Number(item.monto);
			}, 0);

			setTotalAbono(totalMontos);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			fetchNomina();
		}
	}, [id]);

	// Función para convertir las horas (HH:MM) en minutos
	const convertirHoraEnMinutos = (hora) => {
		const [hh, mm] = hora.split(':');
		return parseInt(hh) * 60 + parseInt(mm);
	};

	// Calcular las horas trabajadas
	const calcularHorasTrabajadas = (horaEntrada, horaSalida, inicioReceso, finReceso) => {
		const minutosEntrada = convertirHoraEnMinutos(horaEntrada);
		let minutosSalida;
		const minutosInicioReceso = convertirHoraEnMinutos(inicioReceso);
		const minutosFinReceso = convertirHoraEnMinutos(finReceso);
		if (horaSalida > horamax) {
			minutosSalida = convertirHoraEnMinutos(horamax);
		} else {
			minutosSalida = convertirHoraEnMinutos(horaSalida);
		}
		// Cálculo total: horas trabajadas sin el tiempo de receso
		const minutosTrabajados = minutosSalida - minutosEntrada - (minutosFinReceso - minutosInicioReceso);

		// Convertir minutos trabajados en horas y minutos
		const horas = Math.floor(minutosTrabajados / 60);
		const minutos = minutosTrabajados % 60;
		const totalMinutos = horas * 60 + minutos;

		return { horas, minutos, totalMinutos };
	};

	// Calcular las horas extras trabajadas
	const calcularHorasExtrasTrabajadas = (horaSalida) => {
		const minutosmax = convertirHoraEnMinutos(horamax);
		let minutosSalida = convertirHoraEnMinutos(horaSalida);
		let minutosTrabajados;

		if (horaSalida > horamax) {
			// Cálculo total: horas trabajadas sin el tiempo de receso
			minutosTrabajados = minutosSalida - minutosmax;
		} else {
			minutosTrabajados = 0;
		}
		// Convertir minutos trabajados en horas y minutos
		const horas = Math.floor(minutosTrabajados / 60);
		const minutos = minutosTrabajados % 60;
		const totalMinutos = horas * 60 + minutos;

		return { horas, minutos, totalMinutos };
	};

	const calcularTotales = (data) => {
		let totalPagos = 0;
		let totalAdelantos = 0;

		data.forEach((item) => {
			const horasTrabajadas = calcularHorasTrabajadas(
				item?.horaEntrada,
				item?.horaSalida,
				item?.inicioReceso,
				item?.finReceso
			).totalMinutos;
			const horasExtrasTrabajadas = calcularHorasExtrasTrabajadas(item?.horaSalida).totalMinutos;
			const pago = Math.round(
				Number((currentEmpleado?.salario / minamax) * horasTrabajadas) + (precioHoraExtra / 60) * horasExtrasTrabajadas
			);

			if (item?.estadoPago == false || item?.estadoPago == 0) {
				totalPagos += pago;
			}
			totalAdelantos += item?.adelanto ? item?.adelanto : 0; // Asegúrate de que adelanto no sea null
		});

		return { totalPagos, totalAdelantos };
	};

	const { totalPagos, totalAdelantos } = calcularTotales(data);

	const actualizarNominaEmpleado = async () => {
		try {
			const res = await actualizarNomina(currentNomina?.id, formDataNomina);

			if (res.success) {
				handleCloseHora();
				setAlert(true);
				setMensaje(res.msg);
				fetchNomina();
				handleCloseAdelanto();
				handleClosePagar();
			}
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 3000);
	};

	const actualizarNominasNoPagadas = async () => {
		// Suponiendo que tienes un estado para las nóminas llamado 'nóminas'
		setNominas(
			nóminas.map((nómina) => ({
				...nómina,
				estadoPago: true, // Cambiamos el estado a PAGO
			}))
		);
		handleClosePagar(); // Cerramos el modal
	};

	const crearAbonoEmplado = async () => {
		try {
			const res = await PostAbonoNomina(idNomina, abono);
			if (res.success) {
				setAlert(true);
				setMensaje(res.msg);
				fetchNomina();
				handleCloseAbono();
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 3000);
	};

	const editarAbonoEmpleado = async (obj, monto) => {
		try {
			const res = await actualizarAbono(obj?.id, monto);
			if (res?.success) {
				setMensaje(res?.msg);
				fetchNomina();
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};
	
	const eliminarAbonoEmpleado = async (obj) => {
		try {
			const res = await EliminarAbono(obj?.id);
			if (res?.success) {
				setMensaje(res?.msg);
				fetchNomina();
			}
			console.log(res);
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};

	const closepago = () => {
		setShowPagos(false);
	};

	const pagarNomidaEmpleado = async () => {
		setShowPagos(true);

		try {
			const respago = await PostpagoNomina(data);
			if (respago.success) {
				setAlert(true);
				setMensaje(respago.message);
				closepago();
				fetchNomina();
			}
			console.log(respago);
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setMensaje('');
			setAlert(false);
		}, 3000);
	};

	// metodo para filtrar los detalles de los empleados
	const filterNomina = () => {
		const filters = {
			startDate,
			endDate,
		};

		fetchNomina(filters);
	};


	// metodo para cancelar la cuente  de  los empleados 
	const cancelarcuenta = async () => {
		try {

			const resadelantos = await resetAdelantosNomina(data);
			console.log('se actoalica', resadelantos);
			if (resadelantos?.success) {
				const res = await eliminarAbonos(currentEmpleado?.idEmpl);
				fetchNomina();
				setAlert(true);
				setMensaje(res.message);
				handleShowcancelarcuentaclose();
			}

		} catch (error) {
			console.log(error)
		}

		setTimeout(() => {
			setMensaje('');
			setAlert(false);
		}, 3000);
	}


	return (
		<Fragment>
			<div className="d-flex justify-content-center">
				<AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
			</div>
			<HeaderComponent styleGroup="">
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4 ">EMPLEADOS</TitleH2Component>
				<button onClick={handleShowAbono} type="button" className={`${stylebtn.btn} ${stylebtn.primary} me-4`}>
					ABONAR
				</button>
				<button onClick={handleShowcancelarcuenta} type="button" className={`${stylebtn.btn} ${stylebtn.primary} `}>
					Cancelar cuenta
				</button>
			</HeaderComponent>
			{loading ? (
				<LoaderComponent styleGroup="mt-5" />
			) : (
				<div className="conatiner-global pt-3 mb-5">
					<section>
						<div className="container-fluid">
							<div className="row gy-4">
								<div className="col-12 col-md-6 col-xl-5">
									<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										{currentEmpleado?.Persona?.nombre} {currentEmpleado?.Persona?.apellido}
									</TitleH2Component>
									<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										CC: {currentEmpleado?.Persona?.cedula}
									</TitleH4Component>
									<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										{currentEmpleado?.cargo}
									</TitleH4Component>
									<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-4 text-center">
										${Number(currentEmpleado?.salario).toLocaleString('es-ES')}
									</TitleH4Component>
								</div>

								<div className=" col-md-6 col-xl-7 ">
									<div className={`${stylegeneral.rowpeciales}`}>
										<SelectComponent
											styleGroup={`${stylegeneral.col}`}
											label="Estado"
											styleLabel="fw600 WorkSans color-madera"
											options={options}
											capOpcions={setEstadonomina}
											customPlaceholder="Estado"
											isClearable
										/>

										<InputComponent
											label="Fecha Inicio:"
											styleLabel="fw600 WorkSans"
											styleGroup={`${stylegeneral.col}`}
											htmlFor="bloque"
											type="date"
											value={startDate}
											onChange={(e) => setStartDate(e.target.value)}
										/>

										<InputComponent
											label="Fecha Final:"
											styleLabel="fw600 WorkSans"
											styleGroup={`${stylegeneral.col}`}
											htmlFor="bloque"
											type="date"
											value={endDate}
											onChange={(e) => setEndDate(e.target.value)}
										/>

										<button onClick={filterNomina} type="button" className={`${stylebtn.btn} ${stylebtn.primary} `}>
											Buscar
										</button>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="table-responsive mt-4">
							<table className="table table-bordered tableGeneral">
								<thead>
									<tr>
										<th scope="col " className="color-madera">
											#
										</th>
										<th scope="col" className="color-madera">
											Fecha del Registro
										</th>
										<th scope="col" className="color-madera">
											Hora de Entrada
										</th>
										<th scope="col" className="color-madera">
											Receso
										</th>
										<th scope="col" className="color-madera">
											Hora de salida
										</th>
										<th scope="col" className="color-madera">
											Minutos laborados
										</th>
										<th scope="col" className="color-madera d-flex justify-content-center align-items-center">
											Adelanto
										</th>
										<th scope="col" className="color-madera">
											Minutos extras
										</th>
										<th scope="col" className="color-madera">
											salario por dia
										</th>
										<th scope="col" className="color-madera">
											Estado
										</th>
										<th scope="col" className="color-madera">
											Registro de hora
										</th>
										<th scope="col" className="color-madera">
											Adelanto
										</th>
										<th scope="col" className="color-madera">
											Pago
										</th>
										<th scope="col" className="color-madera">
											Eliminar
										</th>
									</tr>
								</thead>
								{currentPageDatadetalle && currentPageDatadetalle.length !== 0 ? (
									<tbody>
										{currentPageDatadetalle.map((item, index) => {
											const horasTrabajadas = calcularHorasTrabajadas(
												item?.horaEntrada,
												item?.horaSalida,
												item?.inicioReceso,
												item?.finReceso
											).totalMinutos;
											const horasExtrasTrabajadas = calcularHorasExtrasTrabajadas(item?.horaSalida).totalMinutos;
											const pago = Math.round(
												Number((currentEmpleado?.salario / minamax) * horasTrabajadas) +
												(precioHoraExtra / 60) * horasExtrasTrabajadas
											);

											return (
												<tr key={index}>
													<th>{index + 1}</th>
													<td className="text-center">{item?.formattedDate}</td>
													<td className="text-center">{item?.horaEntrada}</td>
													<td className="text-center">
														{item?.inicioReceso} - {item?.finReceso}
													</td>
													<td className="text-center">{item?.horaSalida}</td>
													<td className="text-center">
														{
															calcularHorasTrabajadas(
																item?.horaEntrada,
																item?.horaSalida,
																item?.inicioReceso,
																item?.finReceso
															).totalMinutos

														}
														<br />
														({calcularHorasTrabajadas(
															item?.horaEntrada,
															item?.horaSalida,
															item?.inicioReceso,
															item?.finReceso
														).horas
														}h :
														{calcularHorasTrabajadas(
															item?.horaEntrada,
															item?.horaSalida,
															item?.inicioReceso,
															item?.finReceso
														).minutos
														}m)
													</td>
													<td className="text-center">
														${item?.adelanto === null ? 0 : item?.adelanto.toLocaleString('es-ES')}
													</td>
													<td className="text-center">
														{calcularHorasExtrasTrabajadas(item?.horaSalida).totalMinutos}
														<br />
														({calcularHorasExtrasTrabajadas(
															item?.horaEntrada,
															item?.horaSalida,
															item?.inicioReceso,
															item?.finReceso
														).horas
														}h :
														{calcularHorasExtrasTrabajadas(
															item?.horaEntrada,
															item?.horaSalida,
															item?.inicioReceso,
															item?.finReceso
														).minutos
														}m)
													</td>
													<td className="text-center">${pago.toLocaleString('es-ES')}</td>
													<td
														className={` ${item?.estadoPago == false || item?.estadoPago == 0
															? 'color-plata'
															: 'bg-soccess color-white'
															} text-center`}
													>
														{item?.estadoPago == true || item?.estadoPago == 1 ? 'Pago' : 'No pago'}
													</td>

													<td>
														<button
															onClick={() => handleShowHora(item)}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`}
														>
															<Icon className="color-dark" icon="lucide:clock" />
														</button>
													</td>

													<td>
														<button
															onClick={() => handleShowAdelanto(item)}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex`}
														>
															<Icon icon="game-icons:take-my-money" />
														</button>
													</td>
													<td>
														<button
															onClick={() => handleShowPagar(item)}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`}
														>
															<Icon className="color-dark" icon="mage:exchange-a" />
														</button>
													</td>
													<td>
														<button
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex color-danger`}
															onClick={() => handleShowEliminar(item)}
														>
															<Icon icon="majesticons:delete-bin" />
														</button>
													</td>
												</tr>
											);
										})}
										<tr>
											<td colSpan="5"></td>
											<td className="bg-danger text-white">Total Adelantado</td>
											<td>$ {totalAdelantos.toLocaleString('es-ES')}</td>
											<td className="bg-soccess text-white">Total Pago</td>
											<td>$ {totalPagos.toLocaleString('es-ES')}</td>
										</tr>
										<tr>
											<td colSpan="5"></td>
											<td className="bg-soccess text-white">Abono</td>
											<td onClick={handleShowRegistroA} className="hover-pointer">
												$ {Number(totalAbonos).toLocaleString('es-ES')}
											</td>
											<td className="bg-danger text-white">Debiendo</td>
											<td>$ {Number(totalAdelantos - totalAbonos).toLocaleString('es-ES')}</td>
										</tr>
										<tr>
											<td colSpan="7"></td>

											<td className="bg-azul text-white">Balance de pago</td>
											<td>$ {Number(totalPagos - (totalAdelantos - totalAbonos)).toLocaleString('es-ES')}</td>
										</tr>
									</tbody>
								) : (
									<tbody>
										<tr>
											{' '}
											<td colSpan={11} className="text-center">
												SIN NOMINA
											</td>
										</tr>
									</tbody>
								)}
							</table>
						</div>
					</section>

					{/**Paginador */}
					{pageCount > 1 && (
						<PaginadorComponent
							setCurrentPage={setCurrentPage}
							pages={pageCount}
							styleGroup="d-flex justify-content-center pt-4"
						/>
					)}

					<div className="flotar d-flex alig-items-center">
						<button onClick={pagarNomidaEmpleado} type="button" className={`${stylebtn.btn} ${stylebtn.primary} `}>
							PAGAR
						</button>
						<button className={`${stylebtn.btn_desnudo} ${stylebtn.icon} d-none ms-3 p-0 d-inline-flex color-black`}>
							<Icon icon="fluent-emoji-high-contrast:printer" />
						</button>
					</div>
				</div>
			)}

			{/** Modal para ver registro de ADELANTO o ABONO */}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo={isAdelanto ? 'REGISTRO DE ADELANTO' : 'REGISTRO DE ABONO'}
					styleGroup="d-flex align-items-center modal-general"
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={isAdelanto ? showAdelanto : showAbono}
					handleClose={isAdelanto ? handleCloseAdelanto : handleCloseAbono}
				>
					<div className="container">
						<form action="">
							<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-1">
								{currentEmpleado?.Persona?.nombre}{' '}
							</TitleH2Component>
							<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1">
								{currentEmpleado?.Persona?.apellido}
							</TitleH4Component>
							<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-4">
								CC: {currentEmpleado?.Persona?.cedula}
							</TitleH4Component>

							<div className="d-flex align-items-center mb-3 justify-content-between">
								<TitleH4Component styleGroup="mb-0 me-3">{isAdelanto ? 'ADELANTO' : 'ABONO'}</TitleH4Component>
								<MoneyInputComponent
									styleLabel="fw600 WorkSans"
									htmlFor="bloque"
									type="number"
									value={isAdelanto ? formDataNomina.adelanto : formDataNomina.abono}
									name={isAdelanto ? 'adelanto' : 'abono'}
									onChange={handleInputChangeNomina}
								/>
							</div>
							<div className="d-flex w-100 justify-content-end align-items-start">
								<div className=" mt-4">
									<button
										type="button"
										className={` ${stylebtn.btn_desnudo} ${stylebtn.line} p-0 me-3 `}
										onClick={isAdelanto ? handleCloseAdelanto : handleCloseAbono}
									>
										CANCELAR
									</button>
									<button
										type="button"
										onClick={isAdelanto ? actualizarNominaEmpleado : crearAbonoEmplado}
										className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
									>
										OK
									</button>
								</div>
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>

			{/** Modal para registro de hora */}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo="REGISTRO DE HORAS LABORALES"
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showHora}
					handleClose={handleCloseHora}
				>
					<div className="container">
						<form action="">
							<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-1">
								{currentEmpleado?.Persona?.nombre}{' '}
							</TitleH2Component>
							<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1">
								{currentEmpleado?.Persona?.apellido}
							</TitleH4Component>
							<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-4">
								CC: {currentEmpleado?.Persona?.cedula}
							</TitleH4Component>

							<div className="d-flex align-items-center mb-3 justify-content-between">
								<TitleH4Component styleGroup="mb-0 me-3">HORA DE ENTRADA</TitleH4Component>
								<InputComponent
									styleLabel="fw600 WorkSans"
									htmlFor="bloque"
									type="time"
									value={formDataNomina.horaEntrada}
									name="horaEntrada"
									onChange={handleInputChangeNomina}
								/>
							</div>
							<div className="d-flex align-items-center mb-3 justify-content-between">
								<TitleH4Component styleGroup="mb-0 me-3">INICIO DE RECESO</TitleH4Component>
								<InputComponent
									styleLabel="fw600 WorkSans"
									htmlFor="bloque"
									type="time"
									value={formDataNomina.inicioReceso}
									name="inicioReceso"
									onChange={handleInputChangeNomina}
								/>
							</div>

							<div className="d-flex align-items-center mb-3 justify-content-between">
								<TitleH4Component styleGroup="mb-0 me-3">FIN DE RECESO</TitleH4Component>
								<InputComponent
									styleLabel="fw600 WorkSans"
									htmlFor="bloque"
									type="time"
									value={formDataNomina.finReceso}
									name="finReceso"
									onChange={handleInputChangeNomina}
								/>
							</div>

							<div className="d-flex align-items-center mb-3 justify-content-between">
								<TitleH4Component styleGroup="mb-0 me-3">HORA DE SALIDA</TitleH4Component>
								<InputComponent
									styleLabel="fw600 WorkSans"
									htmlFor="bloque"
									type="time"
									value={formDataNomina.horaSalida}
									name="horaSalida"
									onChange={handleInputChangeNomina}
								/>
							</div>
							<div className="d-flex w-100 justify-content-end aling-items-start">
								<div className=" mt-4">
									<button
										type="button"
										className={` ${stylebtn.btn_desnudo} ${stylebtn.line} p-0 me-3 `}
										onClick={handleCloseHora}
									>
										CANCELAR
									</button>
									<button
										type="button"
										onClick={actualizarNominaEmpleado}
										className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
									>
										OK
									</button>
								</div>
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>

			{/**MODAL PAGAR */}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo={`DESEA ${currentNomina?.estadoPago == false || currentNomina?.estadoPago == 0 ? 'PAGAR' : 'CANCELAR EL PAGO'
						} `}
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showPago}
					handleClose={handleClosePagar}
				>
					<div className="container">
						<form action="">
							<div className="d-flex w-100 justify-content-end aling-items-start">
								<div className=" mt-4">
									<button
										type="button"
										className={` ${stylebtn.btn_desnudo} ${stylebtn.line} p-0 me-3 `}
										onClick={handleClosePagar}
									>
										CANCELAR
									</button>
									<button
										type="button"
										onClick={actualizarNominaEmpleado}
										className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
									>
										OK
									</button>
								</div>
							</div>
						</form>
					</div>
				</ModalGeneralComponent>
			</section>

			{/**Modal Ver Registro de Abonos */}
			<ModalAbono
				mensaje={mensaje}
				show={showRestroA}
				handleClose={handleCloseRegistroA}
				data={dataAbono}
				editarAbono={editarAbonoEmpleado}
				eliminarAbono={eliminarAbonoEmpleado}
			/>
			{/* modal pago de nomina */}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showPagos}
					handleClose={closepago}
				>
					<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-0 text-center">{`Se esta ejecutando el pago  a......`}</TitleH2Component>
					<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-0 text-center">
						{currentEmpleado?.Persona?.nombre} {currentEmpleado?.Persona?.apellido}
					</TitleH2Component>
				</ModalGeneralComponent>
			</section>

			{/** modal eliminar registro **/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere eliminar este registro?`}</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button
								type="button"
								onClick={eliminarRegistroNomina}
								className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
							>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>

			{/* modal cancelar cuenta pendiente  */}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showcancelarCuenta}
					handleClose={handleShowcancelarcuentaclose}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere cancelar el total de la cuenta de  `} {currentEmpleado?.Persona?.nombre} {currentEmpleado?.Persona?.apellido} ?</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleShowcancelarcuentaclose} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button
								type="button"
								onClick={cancelarcuenta}
								className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
							>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};
export default DetalleEmpleadoPage;
