import instance from '../config';

const getFacturas = async (params) => {
	try {
		const response = await instance.get('/api/factura/',{params});
		return response.data;
	} catch (error) {
		console.log(error);
		return [];
	}
}
// metodo para obtener detalle factura para una factura
const obtenerDetalleFactura = async (id) => {
	try {
		const response = await instance.get(`/api/factura/${id}`);
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
};


// Metodo para crear una factura 
const Postfactura = async (idCliente, estado, subtotal,fechaDePago, descuento, financiado, total) => {

	try {
		const response = await instance.post('/api/factura', { idCliente, estado, subtotal,fechaDePago, descuento, financiado, total });
		return response.data;
	} catch (error) {
		return error.response.data;

	}
}

// Metodo para crear una factura detalle
const PostfacturaDetalle = async (isventa,idFactura,dataProductos) => {

	try {
		const response = await instance.post('/api/detallefactura', {isventa, idFactura,dataProductos });
		return response.data;
	} catch (error) {
		return error.response.data;

	}
}

// metodo para buscar una factura 
const BuscarFactura = async (numeroFactura) => {
	try {
		const response = await instance.get(`/api/facturanumero/${numeroFactura}`);
		return response.data;
	} catch (error) {
		console.error(error);
		return error.response.data;
	}
};

// Metodo para eliminar una factura
const EliminarFactura = async (id) => {
    try {
        const response = await instance.delete(`/api/factura/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const PostAbonoFactura = async (facturaId, monto) => {
	try {
		const response = await instance.post('/api/abono', { facturaId, monto });
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// Metodo para actualizar uan factura
const Editarfactura = async (id,estado,subtotal,descuento,financiado,total) => {
    try {
        const response = await instance.put(`/api/factura/${id}`,{estado,subtotal,descuento,financiado,total});
        return response.data;
    } catch (error) {
        console.error(error);
        return error.response.data;
    }
}

const ActualizarAbono = async (id, monto) => {
	try {
		const response = await instance.put(`/api/abono/${id}`, { monto });
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

// Metodo para eliminar un abono 
const EliminarAbono = async (id) => {
	try {
		const response = await instance.delete(`/api/abono/${id}`);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};


export {
	getFacturas,
	obtenerDetalleFactura,
	Postfactura,
	PostfacturaDetalle,
	BuscarFactura,
	EliminarFactura,
	PostAbonoFactura,
	Editarfactura,
	ActualizarAbono,
	EliminarAbono,
};

