import React, { Fragment, useContext, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import stylebtn from '../../styles/btn.module.scss';
import TitleH2Component from '../../components/TitleH2Component';
import TitleH4Component from '../../components/TitleH4Component';
import ParrafoComponent from '../../components/ParrafoComponent';
import styleBtn from '../../styles/btn.module.scss';
import { Icon } from '@iconify/react/dist/iconify.js';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import InputComponent from '../../components/InputComponent';
import styleform from '../../styles/form.module.scss';
import { UserContext } from '../../context/UserContext';
import { BuscarCliente, PostActualizarventa, PostCliente } from '../../api/ApiVenta';
import { BuscarFactura, Editarfactura, EliminarFactura, Postfactura, PostfacturaDetalle } from '../../api/Factura';
import { PostPersona } from '../../api/Empleado';
import AlertComponent from '../../components/AlertComponent';
import LoaderComponent from '../../components/LoaderComponent';
import { Link } from 'react-router-dom';
import MoneyInputComponent from '../../components/MoneyInputComponent';
const CarritoPage = () => {
	const [showEliminar, setShowEliminar] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showaddpendientes, setShowaddpendientes] = useState(false);
	const [showCliente, setShowCliente] = useState(false);
	const [compraExitosa, setCompraExitosa] = useState(false);
	const { carrito, setCarrito, setNumCarrito } = useContext(UserContext);
	const [currentCarrito, setCurrentCarrito] = useState([]);
	const [cantidadVenta, setCantidadVenta] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [totalNeto, setTotalNeto] = useState(0);
	const [descuento, setDescuento] = useState(0);
	const [idCliente, setIdCliente] = useState('');
	const [fechaPago, setFechaPago] = useState(null);
	const [montonFinanciado, setMontonFinanciado] = useState(0)
	const [currentPersona, setCurrentPersona] = useState([]);
	const [currentCliente, setCurrentCliente] = useState([]);
	const [control, setcontrol] = useState([]);
	const [numeroFactura, setNumeroFactura] = useState('');
	const [pendientePorentraga, setPendientePorentrega] = useState({});
	const [pendientevalor, setPendienteValor] = useState('');
	const [alert, setAlert] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentFD, setCurrentFD] = useState([]);
	const [idfactura, serIdfactura] = useState(null);
	const [totalAbonos, setTotalAbono] = useState(0);
	const [formDataPersona, setFormDataPersona] = useState({
		cedula: '',
		nombre: '',
		apellido: '',
		direccion: '',
		celular: '',
	});


	// Manejar el cambio de valor en el input perndiente por entrega 
	const handleInputChangependienteporentrega = (e, itemId) => {
		setPendientePorentrega((prev) => ({
			...prev,
			[itemId]: e.target.value,
		}));
	};



	// Manejar el cambio en el input para el numero de factura 
	const handleInputChangeNumeroFactura = (event) => {
		setNumeroFactura(event.target.value);
	};

	// Manejar el cambio en el input para las fechas de pagos 
	const handleInputChangefechapago = (event) => {
		setFechaPago(event.target.value);
	};
	// Manejar el cambio en el input para el monton financiado
	const handleInputChangemontonfinanciado = (event) => {
		setMontonFinanciado(event.target.value);
	};

	// Manejar el cambio en el input
	const handleInputChangedescuento = (event) => {
		setDescuento(event.target.value);
	};

	// Manejar el cambio en el input
	const handleInputChangePersona = (event) => {
		const { name, value } = event.target;
		setFormDataPersona({
			...formDataPersona,
			[name]: value,
		});
	};
	const [checkboxes, setCheckboxes] = useState({
		isChecked: false,
		isCheckedDescuento: false,
		isCheckedpago: false,
		isCheckedPendiente: false,
	});
	//const [data] = useState([...Array(1).keys()]);


	const handleCloseaddpendientes = () => setShowaddpendientes(false);
	// metodo para obtener el valor pendiente
	const obtnerpendiente = (item) => {
		setShowaddpendientes(true);
		setCurrentCarrito(item);
		setPendienteValor(pendientePorentraga[item.id] || 0)
	};

	const addPendientes = (id, updatevalor) => {
		if (pendientevalor < 0) {
			setMensaje(`la cantidad ingresada es incorrecta `)

		} else {
			const nuevoCarrito = carrito.map(item =>
				item.id === id ? { ...item, ...updatevalor } : item
			);
			setCarrito(nuevoCarrito);
			localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
			handleCloseaddpendientes();
			setAlert(true);
			setMensaje(`Se agragaron ${pendientevalor} pendientes por entregar`)
		}

		setTimeout(() => {
			setAlert(false);
			setMensaje('')
		}, 3000);
	}


	// Metodos para el popup de eliminar
	const handleCloseEliminar = () => setShowEliminar(false);
	const handleShowEliminar = (item) => {
		setShowEliminar(true)
		setCurrentCarrito(item)
	};


	// Metodos para el popup de eliminar
	const handleCloseEdit = () => setShowEdit(false);
	const handleShowEdit = (item) => {
		setCurrentCarrito(item)
		setShowEdit(true)
		setCantidadVenta(item?.cantidad)
	};

	// Metodos para el popup del cliente
	const handleClosecliente = () => {
		setShowCliente(false);
		setCompraExitosa(false);

		if (compraExitosa) {
			window.location.href = "/home"
		}

	};


	const handleShowcliente = async () => {
		if (checkboxes.isCheckedpago) {
			if (numeroFactura === '') {
				setMensaje('Debe agregar el numero de la factura')

			} else {
				const resF = await BuscarFactura(numeroFactura);

				const totalMontos = resF?.data?.Abonos?.reduce((total, item) => {
					return total + Number(item.monto);
				}, 0);

				setTotalAbono(totalMontos);
				if (resF?.success) {
					if (resF?.data?.estado === 0) {
						setMensaje('La factura se encuntra cerrada ')
					} else {

						/*let sinpendientes;
						resF?.data?.DetalleFacturas.forEach((item) => {
							if (item.pendientePorEntregar > 0
								&& item?.Producto?.Clase?.name === carrito?.clase
								&& item?.Producto?.Tipo?.name === carrito?.tipo
								&& item?.Producto?.dimension === carrito?.dimension
							) {
								sinpendientes = true;
							} else {
								sinpendientes = false;

							}
						});*/
						const productoFacturaexiste = carrito.every(productoCarrito => {
							// Busca en la factura productos que coincidan en clase, tipo, y dimensión
							const productoFactura = resF?.data?.DetalleFacturas.find(productoFact =>
								productoFact?.Producto?.Clase?.name === productoCarrito.clase &&
								productoFact?.Producto?.Tipo?.name === productoCarrito.tipo &&
								productoFact?.Producto?.dimension === productoCarrito.dimension &&
								productoFact?.pendientePorEntregar > 0 &&
								productoFact?.pendientePorEntregar >= Number(productoCarrito.cantidad) // Comparar como número
							);

							// Si se encuentra un producto que coincide
							return productoFactura !== undefined; // Verifica si el producto fue encontrado
						});


						if (productoFacturaexiste) {
							setShowCliente(true);
							setFormDataPersona({
								cedula: resF?.data?.Cliente?.Persona?.cedula,
								nombre: resF?.data?.Cliente?.Persona?.nombre,
								apellido: resF?.data?.Cliente?.Persona?.apellido,
								direccion: resF?.data?.Cliente?.Persona?.direccion,
								celular: resF?.data?.Cliente?.Persona?.celular
							})


							// Crear el nuevo array fusionado
							const productosDespacho = carrito.reduce((acc, productoCarrito) => {
								// Busca en la factura productos que coincidan en clase, tipo, y dimensión
								const productoFactura = resF?.data?.DetalleFacturas.find(productoFact =>
									productoFact?.Producto?.Clase?.name === productoCarrito.clase &&
									productoFact?.Producto?.Tipo?.name === productoCarrito.tipo &&
									productoFact?.Producto?.dimension === productoCarrito.dimension &&
									productoFact?.pendientePorEntregar > 0
								);

								// Si se encuentra un producto que coincide
								if (productoFactura) {
									// Crea un nuevo objeto con los datos combinados
									const productoFusionado = {
										idDetalleFactura: productoFactura.id,
										...productoCarrito

									};

									// Añadir al array acumulador
									acc.push(productoFusionado);
								}

								return acc;
							}, []);
							setCurrentFD(productosDespacho);
						} else {
							setMensaje('¡Conflito! Posibles causas - Producto incorrecto - Factura no tiene preductos pendiente - supero la cantidad esperada ¡VERIFIQUE!')
						}

					}
				} else {
					setMensaje(resF.message)
				}
			}
		} else {
			setShowCliente(true);
		}
		setTimeout(() => {
			setMensaje('')
		}, 5000);
	};

	// este es el metodo para sacar el valor del check
	const handleCheckboxChange = (event) => {
		const { id, checked } = event.target;
		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[id]: checked,
		}));
	};

	// metodo para eliminar del carrito de compra 
	const eliminarItem = () => {
		const nuevoCarrito = carrito.filter(item => item.id !== currentCarrito?.id);
		setCarrito(nuevoCarrito);
		setNumCarrito(nuevoCarrito.length);
		localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
		handleCloseEliminar();
	};

	// metodo para editar del carrito de compra 
	const editarItem = (id, updatedItem) => {
		if (updatedItem.cantidad > currentCarrito?.cantidadTotal) {
			setMensaje(`Cantidad es mayor a la disponibles, disponible solo ${currentCarrito?.cantidadTotal} unidades por favor consultar otro puesto`)

		} else if (cantidadVenta <= 0) {
			setMensaje(`la cantidad ingresada es incorrecta `)

		} else {
			const nuevoCarrito = carrito.map(item =>
				item.id === id ? { ...item, ...updatedItem } : item
			);
			setCarrito(nuevoCarrito);
			localStorage.setItem('carrito', JSON.stringify(nuevoCarrito));
			handleCloseEdit();
		}

		setTimeout(() => {
			setMensaje('')
		}, 6000);

	};

	// Manejar el cambio en el input
	const handleInputcantidad = (event) => {
		setCantidadVenta(event.target.value);
	};



	useEffect(() => {
		const carritoGuardado = JSON.parse(localStorage.getItem('carrito')) || [];
		setCarrito(carritoGuardado)

		// Función para calcular el total
		const calcularTotal = (carrito) => {
			return carrito.reduce((total, item) => total + item.valorTotalPorProducto, 0);
		};

		// Llamada a la función para obtener el total
		const total = calcularTotal(carritoGuardado);
		setTotalNeto(total);
	}, [])

	// useEffect para recalcular el total cuando cambie el carrito
	useEffect(() => {
		const calcularTotal = (carrito) => {
			return carrito.reduce((total, item) => total + item.valorTotalPorProducto, 0);
		};

		// Actualiza el total cada vez que 'carrito' cambie
		const total = calcularTotal(carrito);
		setTotalNeto(total);

	}, [carrito]);


	// metodo para consultar la cc
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			consultarCedula();
		}
	};

	const consultarCedula = async () => {
		try {
			const res = await BuscarCliente(formDataPersona.cedula);
			if (res.success) {
				setFormDataPersona({
					cedula: res?.data?.cedula,
					nombre: res?.data?.nombre,
					apellido: res?.data?.apellido,
					direccion: res?.data?.direccion,
					celular: res?.data?.celular,

				})
				setCurrentPersona(res?.data);
				setIdCliente(res?.data?.Clientes[0]?.id);
				setCurrentCliente(res?.data?.Clientes);
			} else {
				setIdCliente('')
				setMensaje(res.msg);
				setFormDataPersona({
					cedula: formDataPersona.cedula,
					nombre: '',
					apellido: '',
					direccion: '',
					celular: '',

				})
			}

			setcontrol(res.success)
		} catch (error) {
			console.log(error)
		}
		setTimeout(() => {
			setMensaje('')
		}, 3000);

	};

	// Metodo para registrar una compra 
	const addCompra = async () => {
		setLoading(true);
		try {
			let total = 0;
			let estado = 0;
			let isAnyCheckedPendiente = false;
			let isventa = true;

			// Verifica si hay al menos un checkbox marcado
			carrito.forEach((item) => {
				if (checkboxes[`isCheckedPendiente${item.id}`] || item?.pendientePorEntregar > 0) {
					isAnyCheckedPendiente = true;
				}
			});


			// para validar si se aplicara el descuento
			if (checkboxes.isCheckedDescuento) {
				total = totalNeto - descuento;
			} else {
				total = totalNeto;
			}

			// esto es para validar el estado de la factura 
			if ((checkboxes.isChecked && isAnyCheckedPendiente) || (checkboxes.isChecked && !isAnyCheckedPendiente)) {
				estado = 1;
			} else if (!checkboxes.isChecked && isAnyCheckedPendiente) {
				estado = 2;
			} else {
				estado = 0;
			}

			// para vaildar los datos de las facturas financianas 
			if (!checkboxes.isChecked) {
				setFechaPago('');
				setMontonFinanciado(0);
			}
			// Verifico si la factura esta paga
			if (checkboxes.isCheckedpago) {
				// actualizamos los pordustos 
				const res = await PostActualizarventa(currentFD);
				if (res?.success) {
					let facturaActualizada;
					setCompraExitosa(true);
					// Ahora limpiamos el carrito.
					localStorage.removeItem('carrito');
					setLoading(false);
					const resF = await BuscarFactura(numeroFactura);
					resF?.data?.DetalleFacturas.forEach((item) => {
						if (item.pendientePorEntregar > 0) {
							facturaActualizada = false;
						} else {
							facturaActualizada = true;

						}
					});
					serIdfactura(resF.data.id);
					if (facturaActualizada && (Number(resF?.data?.financiado).toLocaleString('es-ES') === Number(totalAbonos).toLocaleString('es-ES'))) {
						estado = 0;
						await Editarfactura(resF?.data?.id, estado, resF?.data?.subtotal, resF?.data?.descuento, resF?.data?.financiado, resF?.data?.total);
						
					} else {
						console.log('nooooo')
					}
				}
				setMensaje(res?.message);
		

			} else {
				// si la perosna no exite en la base de datos 
				if (!control || control == false) {
					const respersona = await PostPersona(formDataPersona);
					if (respersona.success) {
						const rescliente = await PostCliente(respersona.data.idP);
						if (rescliente.success) {
							const resf = await Postfactura(
								rescliente.data.id,
								estado,
								totalNeto,
								fechaPago,
								descuento,
								montonFinanciado,
								total
							);
							serIdfactura(resf.data.id);
							if (resf?.success) {

								const resFD = await PostfacturaDetalle(isventa, resf.data.id, carrito);

								if (resFD?.success) {
									setCompraExitosa(true);
									// Ahora limpiamos el carrito.
									localStorage.removeItem('carrito');
									setLoading(false);
								} else {
									await EliminarFactura(resf.data.id);
									setMensaje('Fallo la compra, revise y vuelva a intentarlo');
									setLoading(false);
								}

							}
						}

					}
					setMensaje(respersona.msg);
				} else {
					// si la persona existe en la base de datos pero no es cliente 
					if (idCliente === undefined || currentCliente?.length === 0) {

						const rescliente = await PostCliente(currentPersona?.idP);


						if (rescliente.success) {
							const resf = await Postfactura(
								rescliente.data.id,
								estado,
								totalNeto,
								fechaPago,
								descuento,
								montonFinanciado,
								total
							);

							serIdfactura(resf.data.id);
							if (resf?.success) {
								const resFD = await PostfacturaDetalle(isventa, resf.data.id, carrito);
								if (resFD?.success) {
									setCompraExitosa(true);
									// Ahora limpiamos el carrito.
									localStorage.removeItem('carrito');
									setLoading(false);
								} else {
									await EliminarFactura(resf.data.id);
									setMensaje('Fallo la compra, revise y vuelva a intentarlo');
									setLoading(false);
								}

							}

						}
					} else {
						// Si la perosona es cliente 
						if (idCliente !== '') {
							const resf = await Postfactura(
								idCliente,
								estado,
								totalNeto,
								fechaPago,
								descuento,
								montonFinanciado,
								total
							);
							serIdfactura(resf.data.id);
							if (resf?.success) {
								const resFD = await PostfacturaDetalle(isventa, resf.data.id, carrito);

								if (resFD?.success) {
									setCompraExitosa(true);
									// Ahora limpiamos el carrito.
									localStorage.removeItem('carrito');
									setLoading(false);
								} else {
									await EliminarFactura(resf.data.id);
									setMensaje('Fallo la compra, revise y vuelva a intentarlo');
									setLoading(false);
								}
							}
						}

					}

				}

			}

		} catch (error) {
			console.log(error)
		}

		setTimeout(() => {
			setMensaje('')
		}, 3000);
	}

	return (
		<Fragment>
			<div className='d-flex justify-content-center'>
				<AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
			</div>
			<HeaderComponent styleGroup="">
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">CARRITO</TitleH2Component>
			</HeaderComponent>
			{carrito.length !== 0 ? (
				<div className="conatiner-global pt-3 pb-5">
					<div className="container mt-3">
						<div className="row gy-5">
							<div className="col-xl-9 col-12">
								<div className="table-responsive ">
									<table className="table table-bordered tableGeneral">
										<thead>
											<tr>
												<th scope="col">Pend. Entrega</th>
												<th>Bloque</th>
												<th>Puesto</th>

												<th scope="col" colspan="2">
													Detalle del producto
												</th>
												<th scope="col">Cant.</th>
												<th>Precio</th>
												<th>Subtotal</th>
												<th scope="col"></th>
												<th scope="col"></th>
											</tr>
										</thead>
										<tbody>
											{carrito.map((item, index) => {
												return (
													<tr key={index}>
														<th>
															<div className={`${styleform.checkbox} d-flex justify-content-center mb-2`}>
																<input
																	className="me-2"
																	type="checkbox"
																	value=""
																	id={`isCheckedPendiente${item.id}`}
																	checked={checkboxes[`isCheckedPendiente${item.id}`] !== undefined
																		? checkboxes[`isCheckedPendiente${item.id}`]
																		: item?.pendientePorEntregar > 0}
																	onChange={handleCheckboxChange}
																/>
															</div>
															{(checkboxes[`isCheckedPendiente${item.id}`] || item?.pendientePorEntregar > 0) && (
																<Fragment>

																	<input
																		className="input-small"
																		placeholder="Cantidad: "
																		styleLabel="fw600 WorkSans"
																		styleGroup=" mb-2"
																		htmlFor="bloque"
																		type="number"
																		value={pendientePorentraga[item.id] !== undefined ? pendientePorentraga[item.id] : item?.pendientePorEntregar || ''}
																		onChange={(e) => handleInputChangependienteporentrega(e, item.id)}
																	//onKeyDown={(e) => handleKeyDownobtnerpendiente(e, item)}
																	/>
																	<button type='button' className={`${stylebtn.btn} ${stylebtn.primary}  mt-1`} onClick={() => obtnerpendiente(item)}>OK</button>
																</Fragment>

															)}

														</th>

														<th>B{item.bloque}</th>
														<th>{item.puesto}</th>
														<td colspan="2">{item.tipo} de {item.clase} {item.dimension}</td>
														<td>{item.cantidad}</td>
														<th>${Number(item?.valorPorUnidad).toLocaleString('es-ES')}</th>
														<th>${Number(item?.valorTotalPorProducto).toLocaleString('es-ES')}</th>
														<td>
															<button
																className={`${styleBtn.btn_desnudo} ${styleBtn.icon} ms-3 p-0 d-inline-flex`}
																onClick={() => handleShowEdit(item)}
															>
																<Icon icon="raphael:edit" />
															</button>
														</td>
														<td>
															<button
																className={`${styleBtn.btn_desnudo} ${styleBtn.icon} ms-3 p-0 d-inline-flex color-danger`}
																onClick={() => handleShowEliminar(item)}
															>

																<Icon icon="majesticons:delete-bin" />
															</button>
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
							<div className="col-xl-3 col-12">
								<div className="d-flex flex-column justify-content-center align-items-center">
									<TitleH4Component styleGroup=" color-dark text-center fw700 roboto">
										RESUMEN DE LA COMPRA
									</TitleH4Component>
									<ParrafoComponent styleGroup=" color-dark fw400 WorkSans ">- Precio neto: ${Number(totalNeto).toLocaleString('es-ES')}</ParrafoComponent>
									<ParrafoComponent styleGroup=" color-dark fw400 WorkSans ">- Descuento: ${Number(checkboxes.isCheckedDescuento ? descuento : 0).toLocaleString('es-ES')}</ParrafoComponent>
									<ParrafoComponent styleGroup=" color-dark fw400 WorkSans ">- Total: ${Number(checkboxes.isCheckedDescuento ? totalNeto - descuento : totalNeto).toLocaleString('es-ES')}</ParrafoComponent>
									<div className={`${styleform.checkbox} d-flex justify-content-center mb-2`}>
										<input
											className=" me-2"
											type="checkbox"
											value=""
											id="isChecked"
											checked={checkboxes.isChecked}
											onChange={handleCheckboxChange}
										/>
										<label className="color-dark  fw700" htmlFor="recorddat">
											Financiado
										</label>
									</div>
									{checkboxes.isChecked && (
										<Fragment>
											<MoneyInputComponent
												placeholder="Cantidad a financiar"
												styleLabel="fw600 WorkSans"
												styleGroup=" mb-2"
												htmlFor="bloque"
												type="number"
												name='montonfinanciado'
												value={montonFinanciado}
												onChange={handleInputChangemontonfinanciado}
											/>
											<InputComponent
												placeholder="Cantidad a financiar"
												styleLabel="fw600 WorkSans"
												styleGroup=" mb-2"
												htmlFor="bloque"
												type="date"
												name='fechapago'
												value={fechaPago}
												onChange={handleInputChangefechapago}
											/>
										</Fragment>
									)}
									<div className={`${styleform.checkbox} d-flex justify-content-center mb-2`}>
										<input
											className=" me-2"
											type="checkbox"
											value=""
											id="isCheckedDescuento"
											checked={checkboxes.isCheckedDescuento}
											onChange={handleCheckboxChange}
										/>
										<label className="color-dark  fw700" htmlFor="recorddat">
											Descuento
										</label>
									</div>
									{checkboxes.isCheckedDescuento && (
										<MoneyInputComponent
											placeholder="Ingrese Descuento"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-2"
											htmlFor="descuento"
											type="number"
											name='descuento'
											value={descuento}
											onChange={handleInputChangedescuento}
										/>
									)}

									<div className={`${styleform.checkbox} d-flex justify-content-center mb-2`}>
										<input
											className=" me-2"
											type="checkbox"
											value=""
											id="isCheckedpago"
											checked={checkboxes.isCheckedpago}
											onChange={handleCheckboxChange}
										/>
										<label className="color-dark  fw700" htmlFor="recorddat">
											Fact. Paga
										</label>
									</div>

									{checkboxes.isCheckedpago && (
										<InputComponent
											placeholder="Ingrese Num. de factura"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-2"
											htmlFor="bloque"
											type="text"
											name='numeroFactura'
											value={numeroFactura}
											onChange={handleInputChangeNumeroFactura}
										/>
									)}

									<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans mb-2">{mensaje}</ParrafoComponent>

									<button
										type="button"
										className={`${stylebtn.btn} ${stylebtn.primary}  mt-1`}
										onClick={handleShowcliente}
									>
										Continuar compra
									</button>

								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mt-5 text-center WorkSans">
					CARRITO DE COMPRAS VACIO
				</TitleH2Component>
			)}




			{/** modal eliminar un producto del carrito **/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere eliminar este producto ${currentCarrito?.tipo} ${currentCarrito?.clase} ?`}</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" onClick={eliminarItem} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>

			{/** modal editar cantidad el en carrito de compra**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo="EDITAR CANTIDAD"
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEdit}
					handleClose={handleCloseEdit}
				>
					<form action="">
						<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>
						<InputComponent
							placeholder="Ingrese"
							label="CANTIDAD PRODUCTO:"
							styleLabel="fw600 WorkSans"
							styleGroup=" mb-2"
							htmlFor="bloque"
							type="number"
							name='cantidad'
							value={cantidadVenta}
							onChange={handleInputcantidad}
						/>
						<ParrafoComponent styleGroup=" color-dark fw400 WorkSans">{`Disponible: ${currentCarrito?.cantidadTotal} Unidad`}</ParrafoComponent>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEdit} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" onClick={() => editarItem(currentCarrito?.id, { cantidad: cantidadVenta, valorTotalPorProducto: currentCarrito?.valorPorUnidad * cantidadVenta })} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								OK
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>

			{/** modal ingresar informacion del cliente **/}
			<section>
				<ModalGeneralComponent
					ModalHeader={compraExitosa ? false : true}
					titulo="Información del cliente"
					styleGroup={`d-flex align-items-center modal-general `}
					styleBody={`${compraExitosa ? 'bg-gris-modal ' : ''}`}
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showCliente}
					handleClose={handleClosecliente}
				>
					{loading ? (<LoaderComponent styleGroup="mt-5" />) : !compraExitosa ? (
						<form action="">
							<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>
							<div className="container">
								<div className="row">
									<div className="col-12">

										<label htmlFor={'cedula'} className={`fw600 WorkSans color-madera`}>
											Cedula
										</label>
										<input
											type="text"
											htmlFor="cudula"
											className='form-control fw600 WorkSans mb-4'
											placeholder='Ingrese la cc'
											name='cedula'
											value={formDataPersona.cedula}
											onChange={handleInputChangePersona}
											onKeyDown={handleKeyDown} />
									</div>
									<div className="col-12 col-sm-6">
										<InputComponent
											placeholder="Ingrese"
											label="Nombres:"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-4"
											htmlFor="nombre"
											type="text"
											value={formDataPersona.nombre}
											name="nombre"
											onChange={handleInputChangePersona}
										/>
									</div>
									<div className="col-12 col-sm-6">
										<InputComponent
											placeholder="Ingrese"
											label="Apellidos:"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-4"
											htmlFor="apellido"
											type="text"
											value={formDataPersona.apellido}
											name="apellido"
											onChange={handleInputChangePersona}
										/>
									</div>
									<div className="col-12 col-sm-6">
										<InputComponent
											placeholder="Ingrese"
											label="Direccion:"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-4"
											htmlFor="direccion"
											type="text"
											value={formDataPersona.direccion}
											name="direccion"
											onChange={handleInputChangePersona}
										/>
									</div>
									<div className="col-12 col-sm-6">
										<InputComponent
											placeholder="Ingrese"
											label="Celular:"
											styleLabel="fw600 WorkSans"
											styleGroup=" mb-4"
											htmlFor="celular"
											type="text"
											value={formDataPersona.celular}
											name="celular"
											onChange={handleInputChangePersona}
										/>
									</div>
								</div>
							</div>

							<div className="d-flex justify-content-end">
								<button
									type="button"
									onClick={handleClosecliente}
									className={`btn ${stylebtn.btn} ${stylebtn.danger} `}
								>
									Cancelar
								</button>
								<button type="button" className={` btn ${stylebtn.btn} ${stylebtn.primary} ms-3`} onClick={addCompra}>
									Continuar
								</button>
							</div>
						</form>
					) : (
						<div>
							<div className="d-flex justify-content-end mb-0 align-items-center mb-3">
								<Link to={`/generarfactura/${idfactura}`} className={`btn ${stylebtn.btn} ${stylebtn.primary} me-3`}>
									Generar factura
								</Link>
								<button
									type="button"
									className={`btn ${stylebtn.btn_desnudo} ${stylebtn.icon} p-0 d-inline-flex`}
									onClick={handleClosecliente}
								>
									<Icon icon="mdi:close-thick" />
								</button>
							</div>
							<TitleH2Component styleGroup="box-text color-soccess fw700 roboto mb-0 text-center WorkSans">
								¡¡Compra realizada con éxito!!
							</TitleH2Component>
						</div>
					)}
				</ModalGeneralComponent>
			</section>

			{/** modal para agragar pendientes al carrito de compras **/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showaddpendientes}
					handleClose={handleCloseaddpendientes}
				>
					<form action="">
						<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{mensaje}</ParrafoComponent>

						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">{`Seguro que quiere agregar ${pendientevalor} unidades pendientes al producto ${currentCarrito?.tipo} de ${currentCarrito?.clase} ?`}</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseaddpendientes} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" onClick={() => addPendientes(currentCarrito?.id, { pendientePorEntregar: pendientevalor, valorTotalPorProducto: (currentCarrito?.valorPorUnidad * currentCarrito?.cantidad) + (currentCarrito?.valorPorUnidad * pendientevalor) })} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};

export default CarritoPage;
