import React, { Fragment, useState, useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import stylebtn from '../../styles/btn.module.scss';
import InputComponent from '../../components/InputComponent';
import SelectComponent from '../../components/SelectComponent';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import stylegenral from '../../styles/general.module.scss';
import { getFacturas, EliminarFactura } from '../../api/Factura';
import LoaderComponent from '../../components/LoaderComponent';
import PaginadorComponent from '../../components/PaginadorComponent';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';

const FacturaPage = () => {
	const [estadoF, setEstadoF] = useState();
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentFactura, setCurrentFactura] = useState([]);

	const [showEliminar, setShowEliminar] = useState(false);
	const [mensaje, setMensaje] = useState('');
	const [alert, setAlert] = useState(false);

	const [formDataFecha, setFormDataFecha] = useState({
		fechaI: '',
		fechaFin: '',
	});
	const [cc, setCC] = useState('');

	//const [data] = useState([...Array(12).keys()]); // Simulando datos, un array de 100 elementos
	const [data, setData] = useState([]);
	const itemsPerPage = 7;
	const pageCount = Math.ceil(data?.length / itemsPerPage);
	//const currentPageDataFactura = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

	const filteredData = data.filter((item) => {
		if (!estadoF) return true; // Si no hay filtro seleccionado, mostrar todas las facturas
		return item?.estado === (estadoF === 'ABIERTO' ? 1 : estadoF === 'CERRADO' ? 0 : 2);
	});

	const currentPageDataFactura = filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

	const optionState = [
		{ value: 'ABIERTO', label: 'ABIERTO' },
		{ value: 'CERRADO', label: 'CERRADO' },
		{ value: 'PENDIENTE', label: 'PENDIENTE' },
	];

	// Manejar el cambio en el input
	const handleInputChange = (event) => {
		setCC(event.target.value);
	};

	// Manejar el cambio en el input
	const handleInputChangeFecha = (event) => {
		const { name, value } = event.target;
		setFormDataFecha({
			...formDataFecha,
			[name]: value,
		});
	};

	// metodos para el popup de eliminar
	const handleCloseEliminar = () => setShowEliminar(false);

	const handleShowEliminar = (obj) => {
		setShowEliminar(true);
		setCurrentFactura(obj);
	};

	//metodo para eliminar factura
	const eliminarFactura = async () => {
		try {
			const res = await EliminarFactura(currentFactura?.id);
			if (res.success) {
				setAlert(true);
				setMensaje(res.message);
				fetchData();
				handleCloseEliminar();
			}
			console.log(res);
		} catch (error) {
			console.log(error);
		}

		setTimeout(() => {
			setAlert(false);
		}, 3000);
	};

	//Buscar datos
	const fetchData = async (filters = {}) => {
		setLoading(true);
		try {
			const response = await getFacturas(filters);
			if (response.data && response.data.length > 0) {
				const formattedData = response.data.map((item) => {
					// Calcular la suma de los abonos relacionados
					const sumaAbonos = item.Abonos ? item.Abonos.reduce((total, abono) => total + (abono.monto || 0), 0) : 0;

					console.log('Datos ', response);
					return {
						...item,
						formattfechaDePago: item?.fechaDePago ? new Date(item.fechaDePago).toLocaleDateString() : '',
						formattedDate: new Date(item?.createdAt).toLocaleDateString(),
						// Formatea la fecha sin usar hook
						sumaAbonos, // Agregar la suma al objeto
					};
				});

				// Ordenar las facturas por número de factura
				const cmOrdenados = formattedData.sort((a, b) => b.numeroFactura - a.numeroFactura);

				setData(cmOrdenados);
			} else {
				// Manejar si no hay datos
				setData([]);
			}
		} catch (error) {
			console.error('Error algo está mal:', error);
			setData([]);
		} finally {
			setLoading(false);
		}
	};

	// para ejecutar las metodos automaticos
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setCurrentPage(0);
	}, [data]);

	const handleFilterClick = () => {
		const filters = {
			cedula: cc,
			fechaInicio: formDataFecha.fechaI,
			fechaFin: formDataFecha.fechaFin,
		};

		fetchData(filters);
	};
	return (
		<Fragment>
			<HeaderComponent styleGroup="">
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">FACTURAS</TitleH2Component>
			</HeaderComponent>
			{loading ? (
				<LoaderComponent styleGroup="mt-5" />
			) : (
				<div className="conatiner-global pt-3 mb-5">
					<section className="mb-3">
						<div className="container">
							<div className="row gy-4 d-flex justify-content-xl-end align-items-end">
								{/**d-flex justify-content-end align-items-end*/}
								<div className="col-12 col-md-6 col-xl-3">
									<InputComponent
										label="Fecha Inicio:"
										styleLabel="fw600 WorkSans"
										styleGroup="me-2"
										htmlFor="bloque"
										type="date"
										name="fechaI"
										value={formDataFecha.fechaI}
										onChange={handleInputChangeFecha}
									/>
								</div>
								<div className="col-12 col-md-6 col-xl-3">
									<InputComponent
										label="Fecha Inicio:"
										styleLabel="fw600 WorkSans"
										styleGroup="me-2"
										htmlFor="bloque"
										type="date"
										name="fechaFin"
										value={formDataFecha.fechaFin}
										onChange={handleInputChangeFecha}
									/>
								</div>
								<div className="col-12 col-md-6 col-xl-3">
									<div className="container_search">
										<div className="me-2">
											<Icon icon="gala:search" className="icon-search " />
										</div>

										<InputComponent
											styleGroup="w-100"
											styleinput="w-100"
											type="search"
											search
											name="numero"
											value={cc}
											onChange={handleInputChange}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6 col-xl-3">
									<button
										type="button"
										onClick={handleFilterClick}
										className={`${stylebtn.btn} ${stylebtn.primary} w-100`}
									>
										Buacar
									</button>
								</div>
							</div>
						</div>
					</section>
					<section className="container">
						<div className="d-flex justify-content-end">
							<SelectComponent
								styleGroup={`${stylegenral.wselect} `}
								label="Estado"
								styleLabel="fw600 WorkSans color-madera"
								options={optionState}
								customPlaceholder="Estado"
								capOpcions={setEstadoF}
								isClearable
							/>
						</div>
					</section>
					<section>
						<div className="table-responsive mt-4">
							<table className="table table-bordered tableGeneral">
								<thead>
									<tr>
										<th scope="col " className="color-madera">
											#
										</th>
										<th scope="col " className="color-madera">
											N° Factura
										</th>
										<th scope="col" className="color-madera">
											CC / NIT
										</th>
										<th scope="col" className="color-madera">
											NOMBRES
										</th>
										<th scope="col" className="color-madera">
											VALOR
										</th>
										<th scope="col" className="color-madera">
											PENDIENTE DE PAGO
										</th>
										<th scope="col" className="color-madera">
											FECHA LIMITE DE PAGO
										</th>
										<th scope="col" className="color-madera">
											FECHA DE FACTURA
										</th>
										<th scope="col" className="color-madera">
											ESTADO
										</th>
										<th scope="col" className="color-madera">
											VER
										</th>
										<th>Eliminar</th>
									</tr>
								</thead>

								{currentPageDataFactura && currentPageDataFactura.length !== 0 ? (
									<tbody>
										{currentPageDataFactura.map((item, index) => {
											return (
												<tr key={item.idEmpl}>
													<th>{index + 1}</th>
													<th>{item?.numeroFactura}</th>
													<td>{item?.Cliente?.Persona?.cedula}</td>
													<td>
														{item?.Cliente?.Persona?.nombre} {item?.Cliente?.Persona?.apellido}
													</td>
													<td>${Number(item?.total).toLocaleString('es-ES')}</td>
													<td>${Number(item?.financiado - item?.sumaAbonos).toLocaleString('es-ES')}</td>
													<td>{item?.formattfechaDePago}</td>
													<td>{item?.formattedDate}</td>
													<td
														className={`${item?.estado === 0 ? 'bg-soccess color-white' : ''}
													${item?.estado === 1 ? 'bg-red color-white' : ''}
													${item?.estado === 2 ? 'bg-yellow color-white' : ''}`}
													>
														{item?.estado === 0 ? 'Cerrada' : ''}
														{item?.estado === 1 ? 'Abierta' : ''}
														{item?.estado === 2 ? 'Pendiente' : ''}
													</td>
													<td>
														<Link
															to={`/detallefactura/${item?.id}`}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex`}
														>
															<Icon icon="mdi:file-search-outline" />
														</Link>
													</td>
													<td>
														<button
															onClick={() => {
																handleShowEliminar(item);
															}}
															className={`${stylebtn.btn_desnudo} ${stylebtn.icon} ms-3 p-0 d-inline-flex ${
																item?.estado !== 0 ? '' : 'color-danger'
															} `}
															disabled={item?.estado !== 0}
														>
															<Icon icon="majesticons:delete-bin" />
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								) : (
									<tbody>
										<tr>
											{' '}
											<td colSpan={12} className="text-center">
												SIN FACTURA
											</td>
										</tr>
									</tbody>
								)}
							</table>
						</div>
					</section>
					{pageCount > 1 && (
						<PaginadorComponent
							setCurrentPage={setCurrentPage}
							pages={pageCount}
							styleGroup="d-flex justify-content-center pt-4"
						/>
					)}
				</div>
			)}

			{/** modal eliminar un empleado**/}
			<section>
				<ModalGeneralComponent
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={showEliminar}
					handleClose={handleCloseEliminar}
				>
					<form action="">
						<TitleH2Component styleGroup="box-text color-madera fw700 roboto mb-4 text-center">
							¿ Seguro que quiere eliminar la factura # {currentFactura?.numeroFactura} ?
						</TitleH2Component>

						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleCloseEliminar} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button
								type="button"
								className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}
								onClick={eliminarFactura}
							>
								SI
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};

export default FacturaPage;
