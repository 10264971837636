import React, { Fragment, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import stylebtn from '../../styles/btn.module.scss';
import SelectComponent from '../../components/SelectComponent';
import CardProductoComponent from '../../components/CardProductoComponent';
import PaginadorComponent from '../../components/PaginadorComponent';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import InputComponent from '../../components/InputComponent';
import { getBloques, PostBloques } from '../../api/ApiVenta';
import { getClases } from '../../api/Clase'; // Asegúrate de tener esta importación
import { getTipos } from '../../api/Tipo'; // Asegúrate de tener esta importación
import LoaderComponent from '../../components/LoaderComponent';
import ParrafoComponent from '../../components/ParrafoComponent';
import AlertComponent from '../../components/AlertComponent';
import { FiltrarProducto } from '../../api/Filters';
const HomePege = () => {
	const [show, setShow] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [errorr, setError] = useState('');
	const [alert, setAlert] = useState(false);
	const [mensaje, setMensaje] = useState('');

	//const [data] = useState([...Array(5).keys()]); // Simulando datos, un array de 100 elementos
	const [data, setData] = useState([]);

	// Estado para las clases
	const [dataClases, setDataClases] = useState([]);

	// Estado para los tipos
	const [dataTipo, setDataTipo] = useState([]);

	//Estado para  guarde los valores de los filtros seleccionados
	const [selectedClase, setSelectedClase] = useState('');
	const [selectedTipo, setSelectedTipo] = useState('');
	const [selectedOptionDimension, setSelectedOptionDimension] = useState('');
	const [dimensionOpcion, setDimensionOpcion] = useState([]);


	//Cada vez que el usuario seleccione una clase, tipo o escriba una medida, actualizamos el estado correspondiente.
	const handleClaseChange = (selectedOption) => {
		setSelectedClase(selectedOption.value);
	};

	const handleTipoChange = (selectedOption) => {
		setSelectedTipo(selectedOption.value);
	};

	const handleMedidaChange = (event) => {
		setSelectedOptionDimension(event.target.value);
	};

	// Este useEffect se encarga de obtener los tipos al cargar el componente
	useEffect(() => {
		const fetchTipos = async () => {
			setLoading(true);
			try {
				const response = await getTipos();
				setDataTipo(response.data); // Guardamos las clases en el estado
			} catch (error) {
				console.error('Error al obtener los tipos:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchTipos();
	}, []);

	// Este useEffect se encarga de obtener las clases al cargar el componente
	useEffect(() => {
		const fetchClases = async () => {
			setLoading(true);
			try {
				const response = await getClases();
				setDataClases(response.data); // Guardamos las clases en el estado
			} catch (error) {
				console.error('Error al obtener las clases:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchClases();
	}, []);


	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await getBloques({
				idClase: selectedClase || undefined,
				idTipo: selectedTipo || undefined,
				dimension: selectedOptionDimension || undefined,
			});

			let bloquesFiltrados = response.data;

			// Aplicar el filtro solo si alguno de los filtros está definido
			if (selectedClase || selectedTipo || selectedOptionDimension) {
				bloquesFiltrados = bloquesFiltrados.filter(bloque => bloque.Puestos && bloque.Puestos.length > 0);
			}

			// Ordenar los bloques por el campo "name" antes de asignarlos a la variable de estado
			const bloquesOrdenados = bloquesFiltrados.sort((a, b) => a.name - b.name);

			setData(bloquesOrdenados);

		} catch (error) {
			console.error('Error algo está mal:', error);
		} finally {
			setLoading(false);
		}
	};

	// Agrega `fetchData` como dependencia para que se ejecute cuando los filtros cambien
	useEffect(() => {
		fetchData();
	}, [selectedClase, selectedTipo, selectedOptionDimension]);

	const itemsPerPage = 12;
	const pageCount = Math.ceil(data.length / itemsPerPage);
	const currentPageDataBloque = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);



	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// Manejar el cambio en el input
	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	// Manejar la acción cuando se hace submit del formulario o se clickea un botón
	const aggBloque = () => {
		PostBloques(inputValue)
			.then((response) => {
				// Después de agregar el bloque, obtén todos los bloques actualizados
				setMensaje(response.msg);
				const nuevoBloque = response.data; // El bloque recién agregado devuelto por la API
				// Actualizamos el estado localmente añadiendo el nuevo bloque
				setData((prevData) => {
					const nuevaLista = [...prevData, nuevoBloque];
					return nuevaLista.sort((a, b) => a.name - b.name);
				});
				setShow(false);
				setAlert(true);
				setInputValue('');
			})
			.catch((error) => {
				if (error.response) {
					setError(error.response.data.msg);
					console.log(error.response);
				}
			});

		setTimeout(() => {
			setError('');
		}, [4000]);

		setTimeout(() => {
			setAlert(false);
		}, [5000]);
	};


	const filtroproducto = () => {
		FiltrarProducto({ idClase: selectedClase ?? '', idTipo: selectedTipo ?? '', dimension: selectedOptionDimension ?? '' }).then(response => {
			const formattedOptionsDimension = response?.data?.map(item => ({
				value: item.dimension,
				label: item.dimension
			}));
			setDimensionOpcion(formattedOptionsDimension)

		})
			.catch(error => console.error(error));
	}


	//metodo de filtro 
	useEffect(() => {
		filtroproducto();
	}, [selectedClase, selectedTipo, selectedOptionDimension])
	// para desactivar la alerta

	return (
		<Fragment>
			<div className="d-flex justify-content-center">
				<AlertComponent styleGroup="bg-soccess color-white" mensaje={mensaje} activaalert={alert} />
			</div>
			<HeaderComponent styleGroup="">
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">BLOQUES</TitleH2Component>
				<button type="button" onClick={handleShow} className={`${stylebtn.btn} ${stylebtn.primary} `}>
					Crear bloque
				</button>
			</HeaderComponent>

			<div className="conatiner-global pt-3 mb-5">
				<div className="container">
					<div className="row gy-4 d-flex justify-content-end">
						<div className="col-12 col-md-6 col-lg-4 col-xl-3">
							<SelectComponent
								options={dataClases.map((clase) => ({ value: clase.id, label: clase.name }))}
								customPlaceholder="Seleccione la clase"
								onChange={handleClaseChange}//Para poder filtrar 
								name="clase"
								value={selectedClase}
								capOpcions={setSelectedClase}
								isClearable
							/>
						</div>
						<div className="col-12 col-md-6 col-lg-4 col-xl-3">
							<SelectComponent
								options={dataTipo.map((tipo) => ({ value: tipo.id, label: tipo.name }))}
								customPlaceholder="Seleccione el tipo"
								onChange={handleTipoChange}
								name="tipo"
								value={selectedTipo}
								capOpcions={setSelectedTipo}
								isClearable
							/>
						</div>
						<div className="col-12 col-md-6 col-lg-4 col-xl-3">
							<SelectComponent
								customPlaceholder="Seleccione la medida"
								onChange={handleMedidaChange}
								options={dimensionOpcion}
								name="dimension"
								value={selectedOptionDimension}
								capOpcions={setSelectedOptionDimension}
								isClearable
							/>
						</div>
					</div>
					{loading ? (
						<LoaderComponent styleGroup="mt-5" />
					) : data.length !== 0 ? (
						<div className="row mt-2 gy-4">
							{currentPageDataBloque.map((item, index) => (
								<div className="col-lg-4 col-xl-3 col-6" key={item.id}>
									<CardProductoComponent
										item={item}
										selectedClase={selectedClase}
										selectedTipo={selectedTipo}
										selectedOptionDimension={selectedOptionDimension}
									/>
								</div>
							))}
						</div>
					) : (
						<TitleH2Component styleGroup="box-text color-dark fw700 roboto mt-5 text-center WorkSans">
							BLOQUES VACIOS
						</TitleH2Component>
					)}

					{pageCount > 1 && (
						<PaginadorComponent
							setCurrentPage={setCurrentPage}
							pages={pageCount}
							styleGroup="d-flex justify-content-center pt-4"
						/>
					)}
				</div>
			</div>

			{/** modal Registro de un bloque**/}
			<section>
				<ModalGeneralComponent
					ModalHeader
					titulo="NUEVO BLOQUE"
					styleGroup="d-flex align-items-center modal-general "
					styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
					show={show}
					handleClose={handleClose}
					handleShow={handleShow}
				>
					<form action="">
						<ParrafoComponent styleGroup="text-center color-danger fw400 WorkSans">{errorr}</ParrafoComponent>

						<InputComponent
							placeholder="Ingrese el numero"
							label="Numero del bloque:"
							styleLabel="fw600 WorkSans"
							styleGroup=" mb-4"
							htmlFor="bloque"
							type="number"
							name="numero"
							value={inputValue}
							onChange={handleInputChange}
						/>
						<div className="d-flex justify-content-end">
							<button type="button" onClick={handleClose} className={`btn ${stylebtn.btn} ${stylebtn.danger} `}>
								Cancelar
							</button>
							<button type="button" onClick={aggBloque} className={`btn ${stylebtn.btn} ${stylebtn.primary} ms-3`}>
								Agregar
							</button>
						</div>
					</form>
				</ModalGeneralComponent>
			</section>
		</Fragment>
	);
};

export default HomePege;
