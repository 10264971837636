import '../src/styles/global.scss';
import '../src/styles/fonts.scss';
import BasePage from './pages/home/BasePage';
import LoginPage from './pages/login/LoginPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RouterPrivate from './router/RouterPrivate';
import HomePege from './pages/home/HomePage';
import PuestosPage from './pages/Puestos/PuestosPage';
import ProductPage from './pages/products/ProductPage';
import InventarioPage from './pages/inventario/InventarioPage';
import CarritoPage from './pages/venta/CarritoPage';
import CajaMenorPage from './pages/cajaMenor/CajaMenorPage';
import EmpleadoPage from './pages/empleado/EmpleadoPage';
import DetalleEmpleadoPage from './pages/empleado/DetalleEmpleadoPage';
import FacturaPage from './pages/factura/FacturaPage';
import DetalleFacturaPage from './pages/factura/DetalleFacturaPage';
import CotizacionPage from './pages/cotizacion/CotizacionPage';
import ConfiguracionPage from './pages/configuracion/ConfiguracionPage';
import CategoriaPage from './pages/configuracion/CategoriaPage';
import CambiarClavePage from './pages/configuracion/CambiarClavePage';
import UseCheckTokenExpiration from './hooks/useCheckTokenExpiration';
import ConfiguracionTipoPege from './pages/configuracion/ConfiguracionTipoPege';
import GenerarfacturaPages from './pages/pdf/GenerarfacturaPages';


function App() {
	let user = sessionStorage.getItem(sessionStorage.getItem('token'));
	UseCheckTokenExpiration();
	const router = createBrowserRouter([
		{
			path: '/',
			element: <LoginPage />,
		},
		{
			path: '/base',
			element: <RouterPrivate canActivate={user} component={<BasePage />} />,
		},
		{
			path: '/home',
			element: <RouterPrivate canActivate={user} component={<HomePege />} />,
		},
		{
			path: '/puesto/:id',
			element: <RouterPrivate canActivate={user} component={<PuestosPage />} />,
		},
		{
			path: '/producto/:id',
			element: <RouterPrivate canActivate={user} component={<ProductPage />} />,
		},
		{
			path: '/inventario',
			element: <RouterPrivate canActivate={user} component={<InventarioPage />} />,
		},
		{
			path: '/carrito',
			element: <RouterPrivate canActivate={user} component={<CarritoPage />} />,
		},
		{
			path: '/caja-menor',
			element: <RouterPrivate canActivate={user} component={<CajaMenorPage />} />,
		},
		{
			path: '/empleado',
			element: <RouterPrivate canActivate={user} component={<EmpleadoPage />} />,
		},
		{
			path: '/detalleempleado/:id',
			element: <RouterPrivate canActivate={user} component={<DetalleEmpleadoPage />} />,
		},
		{
			path: '/factura',
			element: <RouterPrivate canActivate={user} component={<FacturaPage />} />,
		},
		{
			path: '/detallefactura/:id',
			element: <RouterPrivate canActivate={user} component={<DetalleFacturaPage />} />,
		},
		{
			path: '/cotizacion',
			element: <RouterPrivate canActivate={user} component={<CotizacionPage />} />,
		},

		{
			path: '/configuracion',
			element: <RouterPrivate canActivate={user} component={<ConfiguracionPage />} />,
		},
		{
			path: '/categoria',
			element: <RouterPrivate canActivate={user} component={<CategoriaPage />} />,
		},
		{
			path: '/cambiarclave',
			element: <RouterPrivate canActivate={user} component={<CambiarClavePage />} />,
		},
		{
			path:'/configurartipo',
			element:<RouterPrivate canActivate={user} component={<ConfiguracionTipoPege/>}/>
		},
		{
			path:'/generarfactura',
			element:<RouterPrivate canActivate={user} component={<GenerarfacturaPages/>}/>
		},
		{
			path:'/generarfactura/:id',
			element:<RouterPrivate canActivate={user} component={<GenerarfacturaPages/>}/>
		}
	]);
	return <RouterProvider router={router} />;
}

export default App;
