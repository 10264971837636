import React, { Fragment, useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import TitleH2Component from '../../components/TitleH2Component';
import TitleH4Component from '../../components/TitleH4Component';
import { Icon } from '@iconify/react/dist/iconify.js';
import stylebtn from '../../styles/btn.module.scss';
import { Link, useParams } from 'react-router-dom';
import ModalGeneralComponent from '../../components/ModalGeneralComponent';
import ParrafoComponent from '../../components/ParrafoComponent';
import CurrencyInput from 'react-currency-input-field';
import stylegeneral from '../../styles/general.module.scss';
import {
	obtenerDetalleFactura,
	PostAbonoFactura,
	ActualizarAbono,
	EliminarAbono,
	Editarfactura,
} from '../../api/Factura';
import LoaderComponent from '../../components/LoaderComponent';
import ModalAbono from '../../components/ModalAbono';
import MoneyInputComponent from '../../components/MoneyInputComponent';

const DetalleFacturaPage = () => {
	const { id } = useParams();
	const [showAbono, setABono] = useState(false);

	const [loading, setLoading] = useState(false);
	const [currentFactura, setCurrentFactura] = useState([]);
	const [idFactura, setIdFactura] = useState('');
	const [data, setData] = useState([]);
	//const [estado, setEstado] = useState(2);
	const [dataAbono, setDataAbono] = useState([]);
	const [totalAbonos, setTotalAbono] = useState(0);
	const [pendienteEntrega, setPendienteEntrega] = useState(0);

	//Para la modal de registo de abono
	const [showRestroA, setRegistroA] = useState(false);

	const [abono, setAbono] = useState(0);

	const [mensaje, setMensaje] = useState('');
	const [alert, setAlert] = useState(false);

	const handleCloseAbono = () => {
		fetchFactura();
		setABono(false);
	};

	useEffect(() => {
		// Calculamos el total de los pendiente por entregar
		const calcularProductoPendiente = data.reduce((acc, item) => {
			return acc + (item?.pendientePorEntregar || 0);
		}, 0);
		setPendienteEntrega(calcularProductoPendiente);
	}, [data]);

	const handleAbono = () => setABono(true);
	const handleClose = () => setABono(false);

	const handleShowRegistroA = () => {
		//fetchAbonoNomina();
		setRegistroA(true);
	};

	const handleCloseRegistroA = () => setRegistroA(false);

	const fetchFactura = async () => {
		setLoading(true);
		try {
			const factura = await obtenerDetalleFactura(id);
			setCurrentFactura(factura?.data);
			setIdFactura(factura?.data?.id);

			const formattedData = (factura?.data?.DetalleFacturas || []).map((item) => ({
				...item,

				formattedDate: new Date(item.createdAt).toLocaleDateString(), // Formatea la fecha sin usar hook
			}));
			setData(formattedData);

			const formattedDataAbono = factura?.data?.Abonos?.map((item) => ({
				...item,
				formattedDate: new Date(item.createdAt).toLocaleDateString(), // Formatea la fecha sin usar hook
				formattedPrice: Number(item.monto).toLocaleString('es-ES'), // Formatea el precio con punto de miles
			}));
			setDataAbono(formattedDataAbono);
			// Sumar los montos
			const totalMontos = factura?.data?.Abonos?.reduce((total, item) => {
				return total + Number(item.monto);
			}, 0);

			setTotalAbono(totalMontos);
			let estado;
			let pendientes;

			if (factura && factura?.data?.DetalleFacturas.length > 0) {
				factura?.data?.DetalleFacturas.forEach((item) => {
					if (item.pendientePorEntregar > 0) {
						pendientes = true;
					} else {
						pendientes = false;

					}
				});
				// Actualizar la factura si los montos coinciden
				if ((Number(factura?.data?.financiado).toLocaleString('es-ES') !== Number(totalMontos).toLocaleString('es-ES') && pendientes) || (Number(factura?.data?.financiado).toLocaleString('es-ES') !== Number(totalMontos).toLocaleString('es-ES') && !pendientes)) {
					estado = 1;
				} else if (Number(factura?.data?.financiado).toLocaleString('es-ES') === Number(totalMontos).toLocaleString('es-ES') && pendientes) {
					estado = 2;
				} else {
					estado = 0
				}

				await Editarfactura(factura?.data?.id, estado, factura?.data?.subtotal, factura?.data?.descuento, factura?.data?.financiado, factura?.data?.total);

				// Actualiza el estado de currentFactura localmente
				setCurrentFactura(prevFactura => ({
					...prevFactura,
					estado,
				}));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			fetchFactura();
		}
	}, [id, totalAbonos]);

	const handleInputChangeFactura = (event) => {
		setAbono(event.target.value);
	};

	//ESTO QUEDA PENDIENTE PARA MAÑANA O MAS TARDE
	const crearAbonoFactura = async () => {
		try {
			if (abono != 0) {
				const res = await PostAbonoFactura(idFactura, abono);
				if (res.success) {
					setAlert(true);
					setMensaje(res.msg);
					fetchFactura();
					handleCloseAbono();
				}
			} else {
				setMensaje('Cantidad incorrecta');
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setAlert(false);
			setMensaje('');
		}, 3000);
	};

	const editarAbonoFactura = async (obj, monto) => {
		try {
			const res = await ActualizarAbono(obj?.id, monto);
			if (res?.success) {
				setMensaje(res?.msg);
				fetchFactura();
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};

	const eliminarAbonoFactura = async (obj) => {
		try {
			const res = await EliminarAbono(obj?.id);
			if (res?.success) {
				setMensaje(res?.msg);
				fetchFactura();
			}
		} catch (error) {
			console.log(error);
		}
		setTimeout(() => {
			setMensaje('');
		}, 3000);
	};

	return (
		<Fragment>
			<HeaderComponent>
				<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-4">DETALLE FACTURA</TitleH2Component>
			</HeaderComponent>
			{loading ? (
				<LoaderComponent styleGroup="mt-5" />
			) : (
				<div className="conatiner-global pt-3 mb-5">
					<section>
						<div className="container-fluid">
							<div className="row gy-4">
								<div className="col-12 col-md-6 col-xl-4">
									<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										{currentFactura?.Cliente?.Persona?.nombre} {currentFactura?.Cliente?.Persona?.apellido}
									</TitleH2Component>
									<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										C.C: {currentFactura?.Cliente?.Persona?.cedula}
									</TitleH4Component>
								</div>
								<div className="col-12 col-md-6 col-xl-4">
									<TitleH2Component
										styleGroup={`${currentFactura?.estado === 0 ? 'bg-soccess color-white' : ''}
													${currentFactura?.estado === 1 ? 'bg-red color-white' : ''}
													${currentFactura?.estado === 2 ? 'bg-yellow color-white' : ''}
													box-text color-dark fw700 roboto mb-1 text-center `}
									>
										{currentFactura?.estado === 0 ? 'Cerrada' : ''}
										{currentFactura?.estado === 1 ? 'Abierta' : ''}
										{currentFactura?.estado === 2 ? 'Pendiente' : ''}
									</TitleH2Component>
								</div>
								<div className="col-12 col-md-6 col-xl-4">
									<TitleH2Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
										{new Date(currentFactura?.createdAt).toLocaleDateString()}
									</TitleH2Component>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="d-flex justify-content-end flex-column align-items-end">
							<button
								onClick={handleAbono}
								className={`${stylebtn.btn_desnudo} ${stylebtn.icon} mb-3 p-0 d-inline-flex color-black`}								
								disabled={currentFactura?.estado === 0 || currentFactura?.estado === 2} // Deshabilita si el estado es 'Cerrada'
							>
								<Icon icon="solar:hand-money-linear" />
							</button>
							
							<Link
								to={currentFactura?.estado !== 0 ? '/home' : '#'} // Deshabilita el enlace si la factura está cerrada
								className={`${stylebtn.btn} ${stylebtn.primary} link mb-3 
                                        ${currentFactura?.estado === 0 ? 'btn-disabled' : ''}`}
								onClick={(e) => currentFactura?.estado === 0 && e.preventDefault()} // Evita la acción si está deshabilitado
							>
														

								DESPACHAR
							</Link>
							<Link to={`/generarfactura/${currentFactura?.id}`} className={`${stylebtn.btn} ${stylebtn.primary}`}>
								GENERAR FACTURA
							</Link>
						</div>
					</section>
					<section>
						<div className="table-responsive mt-4">
							<ParrafoComponent styleGroup=" color-dark fw400 WorkSans fw700">
								N° Factura: {currentFactura?.numeroFactura}
							</ParrafoComponent>

							<table className="table table-bordered tableGeneral">
								<thead>
									<tr>
										<th scope="col" className="color-madera">
											Bloque/Puesto
										</th>
										<th scope="col" className="color-madera">
											Pend. Entrega
										</th>
										<th scope="col" className="color-madera">
											Entregados
										</th>
										<th scope="col" className="color-madera">
											Cantidad producto
										</th>
										<th scope="col" className="color-madera">
											Descripción
										</th>
										<th scope="col" className="color-madera">
											Valor por Unidad
										</th>
										<th scope="col" className="color-madera">
											Valor Total
										</th>
									</tr>
								</thead>
								<tbody>
									{data.map((item, index) => {
										return (
											<tr key={index}>
												<th>
													B{item?.Producto?.Puesto?.Bloque?.name}/ P{item?.Producto?.Puesto?.numero}
												</th>
												<th>{item?.pendientePorEntregar}</th>
												<th>{item?.cantidad}</th>
												<th>{item?.cantidad + item?.pendientePorEntregar}</th>
												<td>
													{item?.Producto?.Tipo?.name} {''}
													{item?.Producto?.Clase?.name} {''}
													{item?.Producto?.dimension}
												</td>
												<td>$ {Number(item?.valorPorUnidad).toLocaleString('es-ES')}</td>
												<td>
													${' '}
													{Number(item?.valorPorUnidad * (item?.cantidad + item?.pendientePorEntregar)).toLocaleString(
														'es-ES'
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan="5"></td>
										<td className="text-right">SUBTOTAL</td>
										<td>$ {Number(currentFactura?.subtotal).toLocaleString('es-ES')}</td>
									</tr>
									<tr>
										<td colSpan="5"></td>
										<td className="text-right">DESCUENTO</td>
										<td>$ {Number(currentFactura?.descuento).toLocaleString('es-ES')}</td>
									</tr>
									<tr>
										<td colSpan="5"></td>
										<td className="text-right">FINANCIADO</td>
										<td>$ {Number(currentFactura?.financiado).toLocaleString('es-ES')}</td>
									</tr>
									<tr>
										<td colSpan="5"></td>
										<td className="text-right">ABONO</td>
										<td onClick={handleShowRegistroA} className="hover-pointer">
											$ {Number(totalAbonos).toLocaleString('es-ES')}
										</td>
									</tr>
									<tr>
										<td colSpan="5"></td>
										<td className="text-right">PENDIENTE POR ENTREGA</td>
										<td>{pendienteEntrega}</td>
									</tr>
									<tr>
										<td colSpan="5"></td>
										<td className="text-right">TOTAL CANCELADO</td>
										<td style={{ backgroundColor: 'silver', fontWeight: 'bold' }}>
											$
											{Number(currentFactura?.total - currentFactura?.financiado + totalAbonos).toLocaleString('es-ES')}
										</td>
									</tr>
									<tr>
										<td colSpan="5"></td>
										<td className="text-right">TOTAL</td>
										<td style={{ backgroundColor: 'lightgreen', fontWeight: 'bold' }}>
											$ {Number(currentFactura?.total).toLocaleString('es-ES')}
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</section>
					;{/** Modal para abonar*/}
					<section>
						<ModalGeneralComponent
							styleGroup="d-flex align-items-center modal-general "
							styleTitle={'box-text color-madera fw700 roboto text-center WorkSans'}
							data={dataAbono ?? []}
							show={showAbono}
							handleClose={handleClose}
						>
							<div className="container">
								<form action="">
									<div className="d-flex align-items-start justify-content-between">
										<div>
											<TitleH4Component styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
												{currentFactura?.Cliente?.Persona?.nombre}
											</TitleH4Component>
											<ParrafoComponent styleGroup="box-text color-dark fw700 roboto mb-1 text-center">
												{currentFactura?.Cliente?.Persona?.apellido}
											</ParrafoComponent>
											<ParrafoComponent styleGroup="box-text color-dark fw700 roboto mb-5 text-center">
												CC: {currentFactura?.Cliente?.Persona?.cedula}
											</ParrafoComponent>
										</div>
										<TitleH4Component styleGroup="mb-0 me-3">
											{new Date(currentFactura?.createdAt).toLocaleDateString()}
										</TitleH4Component>
									</div>
									<div className="d-flex align-items-center justify-content-between mb-4">
										<TitleH4Component styleGroup="mb-0 me-3">ABONO</TitleH4Component>

										<MoneyInputComponent
											styleLabel="fw600 WorkSans"
											styleGroup={`${stylegeneral.col}`}
											//htmlFor="abono"
											value={abono} // Asegúrate de pasar el valor correcto aquí
											name="abono"
											onChange={handleInputChangeFactura}
										/>
									</div>

									<div className="d-flex w-100 justify-content-end aling-items-start">
										<div className=" mt-4">
											<button
												type="button"
												className={` ${stylebtn.btn_desnudo} ${stylebtn.line} p-0 me-3 `}
												onClick={handleClose}
											>
												CANCELAR
											</button>
											<button
												onClick={crearAbonoFactura}
												type="button"
												className={`btn ${stylebtn.btn} ${stylebtn.primary} `}
											>
												OK
											</button>
										</div>
									</div>
								</form>
							</div>
						</ModalGeneralComponent>
					</section>
				</div>
			)}

			{/**Modal Ver Registro de Abonos */}
			<ModalAbono
				mensaje={mensaje}
				show={showRestroA}
				handleClose={handleCloseRegistroA}
				data={dataAbono}
				editarAbono={editarAbonoFactura}
				eliminarAbono={eliminarAbonoFactura}
			/>
		</Fragment>
	);
};

export default DetalleFacturaPage;
